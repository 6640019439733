import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { retry, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface MeineAnfrage {
  // User
  firstName?;
  lastName?;

  // AccessRight
  user?;
  group?;
  folder?;
  accessRights?;

  // General
  requester?;
  jiraTicketId?;
  businessUnit?;
  requestStatus?;
  lastUpdate?;
  requestType?;
  initialRequestDate?;
}

@Injectable({ providedIn: 'root' })
export class MeineAnfragenService {
  constructor(private http: HttpClient) {}

  getMeineAnfragen(): Observable<MeineAnfrage[]> {
    return this.http
      .get<MeineAnfrage[]>(environment.apiUrlPrefix + '/api/requeststatus')
      .pipe(retry(3), timeout(60000));
  }
}
