<p-blockUI [blocked]="loadingFolders || loadingUsers">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>

<div class="bg-white">
  <form
    [formGroup]="addFolderForm"
    (ngSubmit)="onSubmit(addFolderForm.value)"
    class="Formular"
    name="addFolder"
    style="max-width: 61rem;"
  >
    <div class="">
      <div class="mb-5">
        <label class="Label" for="businessUnit">
          Business Unit *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="businessUnit"
          type="text"
          formControlName="businessUnit"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5">
        <label class="Label" for="requester">
          Anforderer *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="requester"
          type="text"
          placeholder=""
          formControlName="requester"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5 sm:flex">
        <div
          class="flex content-between flex-wrap justify-end"
          style="margin-top: 7px; max-width: 180px;"
        >
          <div>
            <label class="Label" for="folderParent">
              Oberordner *:
            </label>
          </div>
          <div style="margin-right: 1.25rem;">
            <p-button
              icon="pi pi-refresh"
              class="mt-2 mb-4"
              (click)="listFolders()"
              showDelay="250"
              hideDelay="250"
              pTooltip="Ordnerliste erneut laden"
              type="button"
            ></p-button>
          </div>
        </div>

        <div class="inline-block">
          <p-tree
            [value]="foldersTree"
            [filter]="true"
            selectionMode="single"
            scrollHeight="272px"
            [(selection)]="selectedFolderParent"
            class="tree"
            [style]="{ height: '357.17px', 'min-width': '333px' }"
            (onNodeSelect)="selectFolder($event)"
            [loading]="loadingFolders"
          ></p-tree>
          <span
            ><p-progressBar
              [style]="{ height: '6px', 'background-color': 'white' }"
              mode="indeterminate"
              *ngIf="loadingFolders"
            ></p-progressBar
          ></span>
          <!--
          <p-dropdown
            name="folderParent-dropdown"
            id="folderParent-dropdown"
            [options]="folderParent"
            formControlName="folderParent"
            showDelay="1000"
            hideDelay="500"
            [filter]="true"
            placeholder=" "
          ></p-dropdown>-->
          <!-- <p-listbox
            [options]="folderParent"
            [(ngModel)]="selectedFolderParent"
            [ngModelOptions]="{ standalone: true }"
            filter="true"
            [listStyle]="{ width: '303px', height: '180px' }"
          >
          </p-listbox>-->
        </div>
      </div>

      <div class="mb-5">
        <label class="Label" for="folderName">
          Ordnerbezeichnung *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="folderName"
          type="text"
          placeholder=""
          formControlName="folderName"
          pTooltip=""
          tooltipEvent="focus"
          pTooltip=""
          (keyup)="validateFolderName()"
        />

        <i
          *ngIf="
            this.folderAvailable &&
              this.selectedFolderParent != null &&
              this.addFolderForm.get('folderName').valid;
            else folderNotAvailable
          "
          class="ui-button-warn pi pi-check align-bottom validIcon text-blue-600"
          pTooltip="Ordnername verfügbar"
        ></i>

        <ng-template #folderNotAvailable>
          <i
            pTooltip="Ordnername nicht verfügbar und/oder Oberordner nicht ausgewählt"
            class="ui-button-warn pi pi-times align-bottom validIcon text-red-600"
          ></i>
        </ng-template>
      </div>

      <div class="mb-5 sm:flex">
        <div class="self-start" style="margin-top: 7px;">
          <div class="Label">
            <label class="" for="folderOwner">
              Besitzer *:
            </label>
          </div>
        </div>
        <div class="inline-block">
          <p-dropdown
            name="folderOwner-dropdown"
            id="folderOwner-dropdown"
            [options]="folderOwner"
            [(ngModel)]="selectedFolderOwner"
            [ngModelOptions]="{ standalone: true }"
            showDelay="1000"
            hideDelay="500"
            [filter]="true"
            placeholder=" "
            optionLabel="label"
            autoWidth="false"
          ></p-dropdown>
        </div>
        <div class="inline-block self-center ml-2 text-lg">
          <i
            class="pi pi-info-circle"
            showDelay="250"
            hideDelay="250"
            pTooltip="Der Besitzer des Ordners ist für die Berechtigungen von Benutzern auf diesen Ordner verantwortlich. Alle Anfragen von Mitarbeitern zu Berechtigungen auf diesen Ordner müssen vom Besitzer (oder seines Vertreters) über ein Jira-Ticket genehmigt werden."
          ></i>
        </div>
        <!--<p-listbox
            [options]="folderOwner"
            [(ngModel)]="selectedFolderOwner"
            [ngModelOptions]="{ standalone: true }"
            filter="true"
            [listStyle]="{ width: '303px', height: '180px' }"
          >
          </p-listbox>-->
      </div>

      <div class="mb-5 sm:flex">
        <div class="self-start" style="margin-top: 7px;">
          <label class="Label" for="folderSecondaryOwners">
            Vertretung Besitzer:
          </label>
        </div>
        <div class="inline-block">
          <p-pickList
            #pl
            [source]="folderSecondaryOwners"
            [target]="selectedFolderSecondaryOwners"
            [sourceStyle]="{ height: '20rem', width: '331px' }"
            [targetStyle]="{ height: '20rem', width: '331px' }"
            sourceHeader="Verfügbare Benutzer"
            targetHeader="Ausgewählte Benutzer"
            filterBy="label"
            [showSourceControls]="false"
            [showTargetControls]="false"
            (onMoveToTarget)="test($event)"
          >
            <ng-template let-user pTemplate="item">
              <div class="ui-helper-clearfix">
                <div>
                  {{ user.label }}
                </div>
              </div>
            </ng-template>
          </p-pickList>
          <!--
          <p-listbox // dritter Entwurf
            name="folderSecondaryOwners-dropdown"
            id="folderSecondaryOwners-dropdown"
            [options]="folderSecondaryOwners"
            [(ngModel)]="selectedFolderSecondaryOwners"
            [ngModelOptions]="{ standalone: true }"
            [filter]="true"
            [showToggleAll]="false"
            [multiple]="true"
            [checkbox]="true"
            [listStyle]="{ width: '303px', height: '180px' }"
            optionLabel="label"
          ></p-listbox>-->
          <!--<p-multiSelect                                    // zweiter Entwurf
            name="folderSecondaryOwners-dropdown"
            id="folderSecondaryOwners-dropdown"
            [options]="folderSecondaryOwners"
            formControlName="folderSecondaryOwners"
            showDelay="1000"
            hideDelay="500"
            [filter]="true"
            placeholder=" "
            [showToggleAll]="false"
            [selectedItemsLabel]="this.f.selectedFolderSecondaryOwners + '...'"
          ></p-multiSelect>-->
          <!--<p-listbox                                        // erster Entwurf
            [options]="folderSecondaryOwners"
            [(ngModel)]="selectedFolderSecondaryOwners"
            [ngModelOptions]="{ standalone: true }"
            filter="true"
            [listStyle]="{ width: '303px', height: '180px' }"
          >
          </p-listbox>-->
        </div>
      </div>
      <!--
        <div>
      <p>
        Selected Vertretung ID:
        <span
          *ngFor="let c of selectedFolderSecondaryOwners"
          style="margin-right: 10px;"
          >{{ c.value.id }};</span
        ></p><br>
      <p>
        Selected Vertretung realname:
      <span
        *ngFor="let c of selectedFolderSecondaryOwners"
        style="margin-right: 10px;"
        >{{ c.value.realname }};</span
      ></p><br>
    <p>
      Selected Vertretung username:
    <span
      *ngFor="let c of selectedFolderSecondaryOwners"
      style="margin-right: 10px;"
      >{{ c.value.username }};</span
    ></p><br>
    <p>
      Selected Vertretung label:
    <span
      *ngFor="let c of selectedFolderSecondaryOwners"
      style="margin-right: 10px;"
      >{{ c.label}};</span
    ></p>
  </div> -->
      <div class="p-0 m-0 flex">
        <span class="text-gray-600 font-thin text-sm self-end"
          >* Pflichtfelder</span
        >
        <div class="ml-auto">
          <p-button
            [disabled]="
              !addFolderForm.valid ||
              selectedFolderParent == null ||
              selectedFolderOwner == null ||
              this.folderAvailable == false
            "
            label="Ordner anlegen"
            class="mr-1"
            type="submit"
            (click)="pl.resetFilter()"
          ></p-button>
          <p-toast></p-toast>
        </div>
      </div>
    </div>
  </form>
</div>
