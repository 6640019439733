<div class="bg-whitemin-h-full p-4" *ngIf="showContactForm">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />

  <!--
    <div class="Formular">
      <div class="inline-block hoverbox_klein shadow-xs mr-16">
        <i class="pi pi-user hoverboxpi"> </i><br />
        Benutzerverwaltung
      </div>
    </div>
    -->

  <div class="bg-white">
    <form
      [formGroup]="createContactForm"
      (ngSubmit)="onSubmit(createContactForm.value)"
      class="Formular"
      name="Contact"
    >
      <div class="grid grid-cols-3 topgrid">
        <div class="hoverbox_klein shadow-xs">
          <i class="pi pi-envelope hoverboxpi"></i><br />
          <span style="font-size: 1.15em;">Kontakt</span>
        </div>
        <div class="mb-10 text-lg userArt col-span-2 relative flex">
          <div class="self-center mx-5 w-full justify-center">
            <span>
              Bei Fragen oder technischen Problemen erstellen Sie bitte eine
              Anfrage</span
            >
          </div>
        </div>
      </div>
      <div class="">
        <div class="seperator">
          <label class="seperatortext">
            Anfrage erstellen
          </label>
        </div>

        <div class="mb-6">
          <label class="Label" for="type">
            Vorgangstyp *:
          </label>
          <!-- <p-dropdown
            name="type-dropdown"
            id="type-dropdown"
            [options]="type"
            [(ngModel)]="selectedType"
            placeholder=""
            formControlName="type"
            showDelay="1000"
            hideDelay="500"
          >
          </p-dropdown>-->
          <p-dropdown [options]="type" formControlName="type">
            <ng-template let-item pTemplate="selectedItem">
              <img
                class="inline"
                src="../assets/{{ item.value }}.svg"
                style="width: 16px; vertical-align: middle;"
              />
              <span style="vertical-align: middle; margin-left: 0.5em;">{{
                item.label
              }}</span>
            </ng-template>
            <ng-template let-type pTemplate="item">
              <div
                class="ui-helper-clearfix"
                style="position: relative; height: 25px;"
              >
                <img
                  src="../../assets/{{ type.value }}.svg"
                  style="width: 24px; position: absolute; top: 1px; left: 5px;"
                />
                <div style="font-size: 14px; float: right; margin-top: 4px;">
                  {{ type.label }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="mb-6">
          <label class="Label" for="summary">
            Zusammenfassung *:
          </label>
          <input
            pInputText
            class="Textfeld"
            id="summary"
            type="text"
            placeholder=""
            formControlName="summary"
            pTooltip=""
            tooltipEvent="focus"
          />
        </div>
        <div class="mb-6">
          <label class="Label" for="requester">
            Anforderer *:
          </label>
          <input
            pInputText
            class="Textfeld"
            id="requester"
            type="text"
            placeholder=""
            formControlName="requester"
            pTooltip=""
            tooltipEvent="focus"
            readonly="true"
          />
        </div>
        <div class="mb-5">
          <label class="Label" style="vertical-align: top;" for="comment">
            Beschreibung:
          </label>
          <textarea
            class="Textfeld min-h max-h"
            pInputText
            id="comment"
            type="text"
            placeholder=""
            formControlName="comment"
            tooltipEvent="focus"
          ></textarea>
        </div>

        <div class="p-0 m-0 flex">
          <span class="text-gray-600 font-thin text-sm self-end"
            >* Pflichtfelder</span
          >
          <div class="ml-auto">
            <p-button
              [disabled]="!createContactForm.valid"
              label="Anfrage absenden"
              class="mr-1"
              type="submit"
            ></p-button>
            <p-toast></p-toast>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
