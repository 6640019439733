import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { retry, timeout, retryWhen, delay, take } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface InfoRow {
  folderPath: string;
  groupName: string;
  username: string;
  realname: string;
  email: string;
  defaultFolder: string;
  permission: string;
  inheritPermissions: string;
}

@Injectable({ providedIn: 'root' })
export class FolderAuthListService {
  constructor(private http: HttpClient) {}

  getReport(): Observable<InfoRow[]> {
    return this.http
      .get<InfoRow[]>(
        environment.moveitApiUrlPrefix +
          '/moveit-api/report/runAccessRightsOverviewReport'
      )
      .pipe(
        // https://stackoverflow.com/a/44979389/170168
        retryWhen((errors) => errors.pipe(delay(1000), take(10))),
        timeout(60000)
      );
  }
}
