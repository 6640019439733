import { Component, OnInit } from '@angular/core';
import {
  UserInformationService,
  UserInformation,
} from '../user-information.service';
import { MoveItService, InfoRow } from './service/user-list.service';
import { Subscription, Observable, PartialObserver } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [MoveItService],
})
export class UserListComponent implements OnInit {
  users: InfoRow[];
  cols: any[];
  loadingReport: boolean = true;

  private moveItUserObservable: Observable<InfoRow[]>;

  private userInformationSubscription: Subscription;
  private moveItUserSubscription: Subscription;

  constructor(
    private moveItService: MoveItService,
    private userInformationService: UserInformationService
  ) {}

  ngOnInit(): void {
    this.cols = [
      //{ field: 'id', header: 'ID' },
      { field: 'realname', header: 'Name' },
      { field: 'email', header: 'E-Mail' },
      { field: 'username', header: 'Benutzername' },
      { field: 'groupname', header: 'Gruppenname' },
      { field: 'description', header: 'Beschreibung' },
    ];
    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.moveItUserObservable = this.moveItService.getUserList();
          this.moveItUserSubscription = this.moveItUserObservable.subscribe({
            next: (users) => {
              this.users = users;
              // console.log('get users: ' + JSON.stringify(users));
              this.loadingReport = false;
            },
            complete: () => {
              this.moveItUserSubscription.unsubscribe();
            },
          });
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  ngOnDestroy(): void {}
}
