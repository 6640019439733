import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MsalInterceptor, MsalModule} from '@azure/msal-angular';
import {NgModule} from '@angular/core';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {ConfirmationService, MessageService} from 'primeng/api';
import {MultiSelectModule} from 'primeng/multiselect';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
//import { TreeModule } from 'primeng/tree';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AboutComponent} from './about/about.component';
import {AnfragenProgressStepsComponent} from './anfragen-progress-steps/anfragen-progress-steps.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BenutzerverwaltungComponent} from './benutzerverwaltung/benutzerverwaltung.component';
import {DialogModule} from 'primeng/dialog';
import {ErrorComponent} from './error/error.component';
import {GroupAddComponent} from './gruppenverwaltung/group-add.component';
import {GroupModifyComponent} from './gruppenverwaltung/group-modify.component';
import {GruppenverwaltungComponent} from './gruppenverwaltung/gruppenverwaltung.component';
import {HomeComponent} from './home/home.component';
import {InfoComponent} from './info/info.component';
import {InputTextModule} from 'primeng/inputtext';
import {MeineAnfragenComponent} from './meine-anfragen/meine-anfragen.component';
import {MoveitComponent} from './moveit/moveit.component';
import {OrdnerberechtigungComponent} from './ordnerverwaltung/ordnerberechtigung.component';
import {OrdnerverwaltungComponent} from './ordnerverwaltung/ordnerverwaltung.component';
import {ReportsComponent} from './reports/reports.component';
import {UserAnlegenComponent} from './user-anlegen/user-anlegen.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {TreeModule} from './tree/tree';
import {BlockUIModule} from 'primeng/blockui';
import {PanelModule} from 'primeng/panel';
import {ChartModule} from 'primeng/chart';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule} from 'primeng/fileupload';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {OrdnerAnlegenComponent} from './ordnerverwaltung/ordner-anlegen.component';
import {OrdnerbesitzerComponent} from './ordnerverwaltung/ordnerbesitzer.component';
import {AdminComponent} from './admin/admin.component';
import {UserListComponent} from './user-list/user-list.component';
import {FolderAuthListComponent} from './folder-auth-list/folder-auth-list.component';
import {GroupListComponent} from './group-list/group-list.component';
import {UserEntsperrenComponent} from './user-entsperren/user-entsperren.component';
import {GruppenbesitzerComponent} from './gruppenverwaltung/gruppenbesitzer.component';
import {AzureCloudInstance, InteractionType, PublicClientApplication} from '@azure/msal-browser';

@NgModule({
  declarations: [
    AboutComponent,
    AnfragenProgressStepsComponent,
    AppComponent,
    BenutzerverwaltungComponent,
    ErrorComponent,
    GroupAddComponent,
    GroupModifyComponent,
    GruppenverwaltungComponent,
    HomeComponent,
    InfoComponent,
    MeineAnfragenComponent,
    MoveitComponent,
    OrdnerberechtigungComponent,
    OrdnerverwaltungComponent,
    ReportsComponent,
    UserAnlegenComponent,
    KontaktComponent,
    OrdnerAnlegenComponent,
    OrdnerbesitzerComponent,
    AdminComponent,
    UserListComponent,
    FolderAuthListComponent,
    GroupListComponent,
    UserEntsperrenComponent,
    GruppenbesitzerComponent,
  ],
  imports: [
    MsalModule.forRoot(new PublicClientApplication(
        {
          auth: {
            // authority:
            //   'https://login.microsoftonline.com/' + environment.tenantId,
            clientId: environment.clientId,
            azureCloudOptions: {
              tenant: environment.tenantId,
              azureCloudInstance: AzureCloudInstance.AzurePublic
            },
            navigateToLoginRequestUrl: true
          },
          cache: {
            // storeAuthStateInCookie: isIEOrEdge(),
            storeAuthStateInCookie: false,
          },
        }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {scopes: ['User.Read', 'openid', 'profile']},
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
          ['https://graph.microsoft.com/v1.0/me/photo/$value', ['User.Read']],
          ['https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto', ['User.Read']],
          [environment.apiUrlPrefix, [`${environment.clientId}/.default`]],
        ]),
      }
    ),
    AppRoutingModule,
    BreadcrumbModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MultiSelectModule,
    PickListModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TreeModule,
    BlockUIModule,
    PanelModule,
    ChartModule,
    SelectButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    FileUploadModule,
  ],
  providers: [
    DatePipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public datepipe: DatePipe) {
  }
}

function isIEOrEdge(): boolean {
  const isIE: boolean =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;
  const isEdge: boolean = window.navigator.userAgent.indexOf('Edge/') > -1;
  // console.log('isIE ' + isIE);
  // console.log('isEdge ' + isEdge);
  return isIE || isEdge;
}
