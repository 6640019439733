<div class="bg-whitemin-h-full p-4">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />

  <!--
  <div class="Formular">
    <div class="inline-block hoverbox_klein shadow-xs mr-16">
      <i class="pi pi-user hoverboxpi"> </i><br />
      Benutzerverwaltung
    </div>
  </div>
  -->

  <p-tabView>
    <p-tabPanel header="Benutzer- und Gruppenliste">
      <app-user-list></app-user-list>
    </p-tabPanel>
    <p-tabPanel header="Ordnerliste">
      <app-folder-auth-list></app-folder-auth-list>
    </p-tabPanel>
  </p-tabView>
</div>
