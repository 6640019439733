import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {MenuItem, Message, MessageService, SelectItem} from 'primeng/api';
import {PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {UserAnlageRequest, UserAnlageResponse, UserAnlegenService,} from './service/user-anlegen.service';

@Component({
  selector: 'app-user-anlegen',
  templateUrl: './user-anlegen.component.html',
  styleUrls: ['./user-anlegen.component.scss'],
  providers: [UserAnlegenService],
})
export class UserAnlegenComponent implements OnInit {
  selectedValue: string;
  selectedGender: string = 'MALE';
  selectedTechUser: boolean = false;

  techUser: boolean;

  items: MenuItem[];

  home: MenuItem;

  bus: SelectItem[];

  lang: SelectItem[];

  createUsersForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  requesterEmail: string;
  requesterBusinessUnit: string;

  disabled: boolean;

  private userInformationSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private userAnlegenService: UserAnlegenService,
    private userInformationService: UserInformationService
  ) {
    const regEx = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

    this.createUsersForm = this.formBuilder.group({
      gender: new UntypedFormControl('MALE'),
      businessUnit: new UntypedFormControl('', Validators.required),
      language: new UntypedFormControl('de', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      firstName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(regEx),
      ]),
      requester: new UntypedFormControl('', Validators.required),
      contactPerson: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      company: new UntypedFormControl(''),
      techUser: new UntypedFormControl(false),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  onSubmit(createUserRequest: UserAnlageRequest) {
    this.disabled = true;
    const userAnlegenSubscription = this.userAnlegenService
      .createUser(createUserRequest)
      .subscribe({
        next: (_) => {
          //console.log('form3: ' + this.createUsersForm.controls.techUser.value);
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
          //console.log('form4: ' + this.createUsersForm.controls.techUser.value);
        },
        error: (err: HttpErrorResponse) => {
          //console.log('form5: ' + this.createUsersForm.controls.techUser.value);
          if (!environment.production) {
            console.log('createUserRequest Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          userAnlegenSubscription.unsubscribe();
          this.disabled = false;
        },
        complete: () => {
          if (!environment.production) {
            console.log('createUserRequest Completed');
          }
          userAnlegenSubscription.unsubscribe();
        },
      } as PartialObserver<UserAnlageResponse>);
  }

  ngOnInit(): void {
    this.disabled = false;
    this.lang = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Englisch', value: 'en' },
      { label: 'Französisch', value: 'fr' },
    ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [
      { label: 'MOVEit', routerLink: '/moveit' },
      { label: 'Benutzer anlegen', routerLink: '/moveit/anlegen' },
    ];

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.requesterBusinessUnit = userInformation.businessUnit;
          this.resetAndPatchForm();
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.userInformationSubscription.unsubscribe();
  }

  public updateFormControl() {
    this.createUsersForm.controls['techUser'].patchValue(this.selectedTechUser);
    this.createUsersForm.controls['gender'].patchValue(this.selectedGender);
    if (this.selectedTechUser) {
      this.createUsersForm.controls.company.setValidators([
        Validators.required,
      ]);
    } else {
      this.createUsersForm.controls.company.clearValidators();
    }
    this.createUsersForm.controls.company.updateValueAndValidity();
  }

  private resetAndPatchForm() {
    this.createUsersForm.reset({
      techUser: false,
      gender: 'MALE',
      businessUnit: this.requesterBusinessUnit,
      language: 'de',
      requester: this.requesterEmail,
    });
    this.selectedTechUser = false;
    this.selectedGender = 'MALE';
    this.disabled = false;
  }

  get f() {
    return this.createUsersForm.controls;
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }
}
