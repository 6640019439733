import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {retry, timeout} from 'rxjs/operators';
import {environment} from '@selfservice-portal-frontend/environments/environment';

export interface GroupAddRequest {
  businessUnit: string;
  requester: string;
  description?: string;
  group: string;
  groupOwner: string;
  groupSecondaryOwners: string[];
}

export interface GroupAddResponse {
  businessUnit: string;
  requester: string;
  group: string;
}

export interface GroupModificationRequest {
  oid?: string;
  businessUnit: string;
  requester: string;
  description?: string;
  group: string;
  groupId: string;
  users: string[];
}

export interface GroupModificationResponse {
  oid?: string;
  businessUnit: string;
  requester: string;
  description?: string;
  group: string;
  users: string[];
}

export interface GruppenBesitzerRequest {
  businessUnit: string;
  requester: string;
  groupId: string;
  groupName: string;
  groupOwner: string;
  groupSecondaryOwners: string[];
}

export interface GruppenBesitzerResponse {
  oid: string;
  businessUnit: string;
  requester: string;
  groupId: string;
  groupName: string;
  groupOwner: string;
  groupSecondaryOwners: string[];
}

export interface BesitzerResponse {
  businessUnit: string;
  requester: string;
  group: string;
  groupOwner: string;
  groupSecondaryOwners: string[];
}

@Injectable({providedIn: 'root'})
export class GruppenverwaltungService {
  constructor(private httpClient: HttpClient) {
  }

  requestGroupModification(
    requestGroupModificationRequest: GroupModificationRequest
  ): Observable<GroupModificationResponse> {
    return this.httpClient
      .post<any>(
        environment.apiUrlPrefix + '/api/group/modify',
        requestGroupModificationRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  requestGroup(
    requestGroupRequest: GroupAddRequest
  ): Observable<GroupAddResponse> {
    return this.httpClient
      .post<any>(
        environment.apiUrlPrefix + '/api/group/add',
        requestGroupRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  changeOwner(
    gruppenBesitzerRequest: GruppenBesitzerRequest
  ): Observable<GruppenBesitzerResponse> {
    return this.httpClient
      .post<GruppenBesitzerResponse>(
        environment.apiUrlPrefix + '/api/group/owner/modify',
        gruppenBesitzerRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  currentOwner(
    groupId: string,
    recursive: boolean
  ): Observable<BesitzerResponse> {
    return this.httpClient
      .post<BesitzerResponse>(environment.apiUrlPrefix + '/api/group/owner', {
        groupId: groupId,
        recursive: recursive,
      })
      .pipe(retry(3), timeout(60000));
  }

  groupOwners(): Observable<BesitzerResponse[]> {
    return this.httpClient
      .get<BesitzerResponse[]>(environment.apiUrlPrefix + '/api/group/owners')
      .pipe(retry(3), timeout(60000));
  }
}
