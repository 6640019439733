// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlPrefix: '',
  moveitApiUrlPrefix: '',
  jiraApiUrlPrefix: '',
  // jiraBaseUrl: 'https://jira.test.toolchain.direct-services.kreuzwerker.net/projects/BPGMG/issues/',
  // jiraBaseUrl: 'https://jira-old.bpghub.de/projects/BPGMG/issues/',
  jiraBaseUrl: 'https://bmshub-dev-sandbox.atlassian.net/browse/',
  clientId: '69c49331-f93a-4ea5-bb4f-264821cd2302',
  tenantId: '1ca8bd94-3c97-4fc6-8955-bad266b43f0b',
  showContactForm: false,
  showAddFolderForm: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
