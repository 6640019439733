import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {Message, MessageService, SelectItem} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItGroup, MoveItService, MoveItUser,} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {
  GroupModificationRequest,
  GroupModificationResponse,
  GruppenverwaltungService,
} from './service/gruppenverwaltung.service';

@Component({
  selector: 'app-group-modify',
  templateUrl: './group-modify.component.html',
  styleUrls: ['./group-modify.component.scss'],
  providers: [MoveItService, GruppenverwaltungService],
})
export class GroupModifyComponent implements OnInit {
  modifyGroupForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  selectedGroup: MoveItGroup;
  groupList: MoveItGroup[];
  groups: SelectItem[];
  users: MoveItUser[];

  groupDescriptionMap = new Map();

  _loadingUserList: boolean = false;
  _loadingUsers: boolean = false;
  _loadingGroups: boolean = false;

  set loadingUserList(value: boolean) {
    this._loadingUserList = value;
    this.loading.emit(this._loadingGroups || this._loadingUserList || this._loadingUsers);
  }
  get loadingUserList(): boolean {
    return this._loadingUserList;
  }

  set loadingUsers(value: boolean) {
    this._loadingUsers = value;
    this.loading.emit(this._loadingGroups || this._loadingUserList || this._loadingUsers);
  }
  get loadingUsers(): boolean {
    return this._loadingUsers;
  }

  set loadingGroups(value: boolean) {
    this._loadingGroups = value;
    this.loading.emit(this._loadingGroups || this._loadingUserList || this._loadingUsers);
  }
  get loadingGroups(): boolean {
    return this._loadingGroups;
  }


  @Output() loading = new EventEmitter<boolean>();

  usersAvailable: any[];
  usersSelected: any[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  icons;

  private userInformationSubscription: Subscription;
  private moveItUserSubscription: Subscription;
  private moveItGroupSubscription: Subscription;
  private moveItUsersByGroupSubscription: Subscription;

  private moveItUserObservable: Observable<MoveItUser[]>;
  private moveItGroupObservable: Observable<MoveItGroup[]>;
  private moveItUsersByGroupObservable: Observable<MoveItUser[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userInformationService: UserInformationService,
    private moveItService: MoveItService,
    private gruppenverwaltungService: GruppenverwaltungService,
    private messageService: MessageService
  ) {
    this.modifyGroupForm = this.formBuilder.group({
      businessUnit: new UntypedFormControl('', Validators.required),
      requester: new UntypedFormControl('', Validators.required),
      groupName: new UntypedFormControl('', Validators.required),
      groupDescription: new UntypedFormControl(''),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  ngOnInit() {
    this.groups = [{label: 'Bitte warten...', value: null}];
    this.usersSelected = [];
    // this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  onSubmit(addModifyGroupRequest) {
    const userList: string[] = [];

    this.usersSelected.forEach((selectedUser) => {
      userList.push(selectedUser.value.username);
    });

    const req: GroupModificationRequest = {
      businessUnit: this.modifyGroupForm.get('businessUnit').value,
      group: this.selectedGroup.value.name,
      groupId: this.selectedGroup.value.id,
      requester: this.modifyGroupForm.get('requester').value,
      description: this.modifyGroupForm.get('groupDescription').value,
      users: userList,
    };

    const requestGroupModificationSubscription = this.gruppenverwaltungService
      .requestGroupModification(req)
      .subscribe({
        next: (result) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('requestGroupModification Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          requestGroupModificationSubscription.unsubscribe();
        },
        complete: () => {
          if (!environment.production) {
            console.log('requestGroupModification Completed');
            requestGroupModificationSubscription.unsubscribe();
          }
        },
      } as PartialObserver<GroupModificationResponse>);
  }

  sortLists(event) {
    // ignore
  }

  selectGroup(event) {
    this.selectedGroup = event.value;

    this.usersAvailable = [];
    this.usersSelected = [];

    this.modifyGroupForm.patchValue({
      groupDescription: '',
    });

    if (event.value == null) {
      return;
    }

    this.loadingUsers = true;

    this.moveItUsersByGroupObservable = this.moveItService.getUsersInGroup(
      this.selectedGroup.value.name
    );

    this.moveItUsersByGroupSubscription = this.moveItUsersByGroupObservable.subscribe(
      {
        next: (usersByGroup) => {
          this.usersSelected = usersByGroup;
          const tmpList = [];

          for (const availableUser of this.users) {
            let addUser: boolean = true;
            for (const selectedUser of this.usersSelected) {
              if (selectedUser.label == availableUser.label) {
                addUser = false;
                break;
              }
            }
            if (addUser) {
              tmpList.push(availableUser);
            } else {
              if (!environment.production) {
                console.log('filter ' + availableUser.label);
              }
            }
          }

          const description = this.groupDescriptionMap.get(
            this.selectedGroup.value.name
          );
          this.modifyGroupForm.patchValue({
            groupDescription: description,
          });

          this.usersAvailable = tmpList;
          this.loadingUsers = false;
        },
        complete: () => {
          this.moveItUsersByGroupSubscription.unsubscribe();
        },
      }
    );
  }

  private resetAndPatchForm() {
    this.selectedGroup = null;
    this.usersAvailable = [];
    this.usersSelected = [];
    this.modifyGroupForm.reset();
    this.modifyGroupForm.patchValue({
      businessUnit: this.requesterBusinessUnit,
      requester: this.requesterEmail,
    });
    this.groups = [];
    // this.groups = [{label: 'Gruppe auswählen...', value: null}];
    this.groupList.forEach((group) => {
      this.groups.push({
        label: group.label,
        value: group,
      });
      this.groupDescriptionMap.set(group.label, group.value.description);
    });
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  private consoleLog() {
    if (!environment.production) {
      this.icons = document.querySelectorAll('.pi');
      console.log(this.icons);
    }
  }

  loadUserInformations() {
    this.loadingGroups = true;
    this.loadingUserList = true;

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          if (userInformation.businessUnit != '') {
            this.requesterEmail = userInformation.preferredName;
            this.requesterBusinessUnit = userInformation.businessUnit;

            this.moveItGroupObservable = this.moveItService.getGroupList();
            this.moveItGroupSubscription = this.moveItGroupObservable.subscribe(
              {
                next: (groups) => {
                  this.groupList = groups;
                  this.resetAndPatchForm();
                },
                complete: () => {
                  this.moveItGroupSubscription.unsubscribe();
                  this.loadingGroups = false;

                  this.moveItUserObservable = this.moveItService.getUserList();
                  this.moveItUserSubscription = this.moveItUserObservable.subscribe({
                    next: (users) => {
                      this.users = users;
                    },
                    complete: () => {
                      this.moveItUserSubscription.unsubscribe();
                      this.loadingUserList = false;
                    },
                  });
                },
              }
            );
          }
        },
      }
    );
  }

}
