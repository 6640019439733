import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {
  InfoRow,
  FolderAuthListService,
} from './service/folder-auth-list.service';
import { Observable, Subscription } from 'rxjs';
import {
  UserInformationService,
  UserInformation,
} from '../user-information.service';
import { environment } from '@selfservice-portal-frontend/environments/environment';

@Component({
  selector: 'app-folder-auth-list',
  templateUrl: './folder-auth-list.component.html',
  styleUrls: ['./folder-auth-list.component.scss'],
  providers: [],
})
export class FolderAuthListComponent implements OnInit {
  items: MenuItem[];
  home: MenuItem;

  cols: any[];
  infoRows: InfoRow[];
  loadingReport: boolean = true;
  _selectedColumns: any[];
  exportColumns: any[];

  displayInfoDialog: boolean = false;

  private folderAuthListObservable: Observable<InfoRow[]>;
  private folderAuthListSubscription: Subscription;
  private userInformationSubscription: Subscription;

  constructor(
    private userInformationService: UserInformationService,
    private folderAuthListService: FolderAuthListService
  ) {}

  ngOnInit(): void {
    this.home = { icon: 'pi pi-home', routerLink: '/' };

    this.cols = [
      { field: 'folderPath', header: 'Ordner' },
      { field: 'groupName', header: 'Gruppe' },
      { field: 'username', header: 'Benutzer' },
      { field: 'realname', header: 'Name' },
      { field: 'email', header: 'E-Mail' },
      { field: 'defaultFolder', header: 'Standardordner' },
      { field: 'permission', header: 'Berechtigungen' },
      { field: 'inheritPermissions', header: 'Berechtigungen geerbt von' },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    // workaround for BPGSSP-69
    this._selectedColumns = [];
    this._selectedColumns.push(this.cols[0]);
    this._selectedColumns.push(this.cols[1]);
    this._selectedColumns.push(this.cols[2]);
    this._selectedColumns.push(this.cols[3]);
    this._selectedColumns.push(this.cols[4]);
    this._selectedColumns.push(this.cols[6]);
    this._selectedColumns.push(this.cols[7]);

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.folderAuthListObservable = this.folderAuthListService.getReport();
          this.folderAuthListSubscription = this.folderAuthListObservable.subscribe(
            {
              next: (infoRows) => {
                this.infoRows = infoRows;
              },
              complete: () => {
                this.folderAuthListSubscription.unsubscribe();
                this.loadingReport = false;
              },
              error: (error) => {
                this.folderAuthListSubscription.unsubscribe();
                this.loadingReport = false;
                if (!environment.production) {
                  console.log(error);
                }
              },
            }
          );
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.userInformationSubscription.unsubscribe();
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const headerColumns: string[] = [];
      this.cols.forEach((col) => headerColumns.push(col.field));

      const worksheet = xlsx.utils.json_to_sheet(this.infoRows, {
        skipHeader: false,
        header: headerColumns,
      });

      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'moveit-report');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const data: Blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      FileSaver.default(
        data,
        fileName + '-export-' + new Date().getTime() + '.xlsx'
      );
    });
  }

  showInfoDialog() {
    this.displayInfoDialog = true;
  }
}
