<div class="bg-whitemin-h-full p-4">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />
  <p-tabView>
    <p-tabPanel header="Benutzer anlegen">
      <app-user-anlegen></app-user-anlegen>
    </p-tabPanel>
    <p-tabPanel header="Benutzer entsperren">
      <app-user-entsperren></app-user-entsperren>
    </p-tabPanel>
  </p-tabView>
</div>
