<div class="bg-white min-h-full p-4">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />
  <p-blockUI [blocked]="showLoading">
    <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
  </p-blockUI>
  <p-tabView (onChange)="tabViewChange($event, tabView)">
    <p-tabPanel header="Gruppe anlegen">
      <app-group-add></app-group-add>
    </p-tabPanel>
    <p-tabPanel header="Gruppe ändern">
      <app-group-modify></app-group-modify>
    </p-tabPanel>
    <p-tabPanel header="Besitzer ändern">
      <app-gruppenbesitzer></app-gruppenbesitzer>
    </p-tabPanel>
  </p-tabView>
</div>
