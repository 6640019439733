import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TabView} from "primeng/tabview";
import {environment} from "../../environments/environment";
import {GroupAddComponent} from "./group-add.component";
import {GroupModifyComponent} from "./group-modify.component";
import {GruppenbesitzerComponent} from "./gruppenbesitzer.component";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-gruppenverwaltung',
  templateUrl: './gruppenverwaltung.component.html',
  styleUrls: ['./gruppenverwaltung.component.scss'],
  providers: [],
})
export class GruppenverwaltungComponent implements OnInit, OnDestroy, AfterViewInit{
  items: MenuItem[];
  home: MenuItem;
  icon1;
  icon2;
  iconsFrame;
  dataLoadedMap;
  showLoading: boolean = false;
  private subscriptions: Subscription[] = [];

  @ViewChild(TabView) tabView: TabView;
  @ViewChild(GroupAddComponent) groupAddComponent: GroupAddComponent;
  @ViewChild(GroupModifyComponent) groupModifyComponent: GroupModifyComponent;
  @ViewChild(GruppenbesitzerComponent) gruppenbesitzerComponent: GruppenbesitzerComponent;

  constructor() {
  }

  ngAfterViewInit(): void {
    // Subscribe to the loading event emitters of each component
    this.subscriptions.push(this.groupAddComponent.loading.subscribe(value => this.showLoading = value));
    this.subscriptions.push(this.groupModifyComponent.loading.subscribe(value => this.showLoading = value));
    this.subscriptions.push(this.gruppenbesitzerComponent.loading.subscribe(value => this.showLoading = value));
    }

  ngOnInit(): void {
    this.home = {icon: 'pi pi-home', routerLink: '/'};
    this.items = [
      {label: 'MOVEit', routerLink: '/moveit'},
      {label: 'Gruppenverwaltung', routerLink: '/moveit/gruppen'},
    ];

    // first tab will be loaded automatically
    this.dataLoadedMap = new Map();
    this.dataLoadedMap.set("Gruppe anlegen", true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  tabViewChange(event, tabView: TabView) {
    const headerValue = tabView.tabs[event.index].header;

    // ITS-41604: [SSP] Edge zeigt keine Ordner/Gruppen/Benutzerlisten an
    if (this.dataLoadedMap.get(headerValue)) {
      if (!environment.production) {
        console.log("already loaded " + headerValue);
      }
    } else {
      if ("Gruppe anlegen" == headerValue) {
        this.groupAddComponent.loadUserInformations();
      } else if ("Gruppe ändern" == headerValue) {
        this.groupModifyComponent.loadUserInformations();
      } else if ("Besitzer ändern" == headerValue) {
        this.gruppenbesitzerComponent.loadUserInformations();
      }
      this.dataLoadedMap.set(headerValue, true);
    }
  }

}
