import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { BenutzerverwaltungComponent } from './benutzerverwaltung/benutzerverwaltung.component';
import { ErrorComponent } from './error/error.component';
import { GruppenverwaltungComponent } from './gruppenverwaltung/gruppenverwaltung.component';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { MeineAnfragenComponent } from './meine-anfragen/meine-anfragen.component';
import { MoveitComponent } from './moveit/moveit.component';
import { MsalGuard } from '@azure/msal-angular';
import { OrdnerverwaltungComponent } from './ordnerverwaltung/ordnerverwaltung.component';
import { ReportsComponent } from './reports/reports.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: 'info', component: InfoComponent },
      { path: 'anfragen', component: MeineAnfragenComponent },
      { path: 'moveit', component: MoveitComponent },
      { path: 'moveit/benutzer', component: BenutzerverwaltungComponent },
      { path: 'moveit/gruppen', component: GruppenverwaltungComponent },
      { path: 'moveit/ordner', component: OrdnerverwaltungComponent },
      { path: 'moveit/reports', component: ReportsComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'about', component: AboutComponent },
      { path: 'kontakt', component: KontaktComponent },
      { path: '**', component: ErrorComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
