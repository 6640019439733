import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface UserAnlageRequest {
  title: string;
  businessUnit: string;
  language: string;
  lastName: string;
  firstName: string;
  requester: string;
  contactPerson: string;
  phone: string;
  company: string;
  techUser: boolean;
}

export interface UserAnlageResponse {
  title: string;
  businessUnit: string;
  language: string;
  lastName: string;
  firstName: string;
  requester: string;
  contactPerson: string;
  phone: string;
  company: string;
  techUser: boolean;
  aggregateRootId: string;
}

@Injectable({ providedIn: 'root' })
export class UserAnlegenService {
  constructor(private httpClient: HttpClient) {}

  createUser(
    userAnlageRequest: UserAnlageRequest
  ): Observable<UserAnlageResponse> {
    return this.httpClient
      .post<UserAnlageResponse>(
        environment.apiUrlPrefix + '/api/user/request',
        userAnlageRequest
      )
      .pipe(retry(3), timeout(60000));
  }
}
