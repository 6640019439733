import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {MenuItem, Message, MessageService, SelectItem, TreeNode,} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItService} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {JiraUser, JiraUserService} from '../jira.service';
import {
  BesitzerResponse,
  OrdnerBesitzerRequest,
  OrdnerBesitzerResponse,
  OrdnerService,
} from './service/ordner.service';

@Component({
  selector: 'app-ordnerbesitzer',
  templateUrl: './ordnerbesitzer.component.html',
  styleUrls: ['./ordnerbesitzer.component.scss'],
})
export class OrdnerbesitzerComponent implements OnInit {
  foldersTree: TreeNode[];
  items: MenuItem[];
  home: MenuItem;
  bus: SelectItem[];
  changeOwnerForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  folder: SelectItem[];
  folderOwner: JiraUser[];
  folderSecondaryOwners: JiraUser[];

  selectedFolder: TreeNode;
  selectedFolderOwner: JiraUser;
  selectedFolderSecondaryOwners: JiraUser[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  users: JiraUser[];

  loadingFolders: boolean;
  blocked: boolean = false;
  icon;

  private jiraUserSubscription: Subscription;
  private jiraUserObservable: Observable<JiraUser[]>;

  private userInformationSubscription: Subscription;

  private aktuellerBesitzerSubscription: Subscription;
  private aktuellerBesitzerObservable: Observable<BesitzerResponse>;

  constructor(
    private moveItService: MoveItService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private ordnerService: OrdnerService,
    private jiraUserService: JiraUserService,
    private userInformationService: UserInformationService
  ) {
    this.changeOwnerForm = this.formBuilder.group({
      businessUnit: new UntypedFormControl('', Validators.required),
      requester: new UntypedFormControl('', Validators.required),
      folderOwner: new UntypedFormControl(''),
      folder: new UntypedFormControl(''),
      folderSecondaryOwners: new UntypedFormControl(''),
      selectedFolderOwner: new UntypedFormControl(''),
      selectedFolderSecondaryOwners: new UntypedFormControl(''),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  private treeNodeObservable: Observable<TreeNode[]>;

  private folderSubscription: Subscription;

  onSubmit(createFolderRequest: OrdnerBesitzerRequest) {
    this.blocked = true;
    const folderSecondaryOwnersList: string[] = [];

    if (this.selectedFolderSecondaryOwners != null) {
      this.selectedFolderSecondaryOwners.forEach((selectedSecondaryOwner) => {
        folderSecondaryOwnersList.push(selectedSecondaryOwner.value.emailAddress);
      });
    }

    const req: OrdnerBesitzerRequest = {
      businessUnit: this.changeOwnerForm.get('businessUnit').value,
      requester: this.changeOwnerForm.get('requester').value,
      folderPath: this.selectedFolder.data,
      folderId: this.selectedFolder.key,
      folderOwner: this.selectedFolderOwner.value.emailAddress,
      folderSecondaryOwners: folderSecondaryOwnersList,
    };
    if (!environment.production) {
      console.log(req);
    }

    const besitzerAendernSubscription = this.ordnerService
      .changeOwner(req)
      .subscribe({
        next: (_) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          console.log('createFolderRequest Error');
          this.messageService.add(this.failureMessageToast(err.message));
          besitzerAendernSubscription.unsubscribe();
          this.blocked = false;
        },
        complete: () => {
          console.log('createFolderRequest Completed');
          besitzerAendernSubscription.unsubscribe();
          this.blocked = false;
        },
      } as PartialObserver<OrdnerBesitzerResponse>);
    this.loadUsers();
  }

  ngOnInit() {
    // this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  loadUserInformations() {
    this.loadingFolders = true;
    this.folderOwner = [{label: 'Bitte warten...', value: null}];
    this.folderSecondaryOwners = [{label: 'Bitte warten...', value: null}];

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.requesterBusinessUnit = userInformation.businessUnit;

          this.jiraUserObservable = this.jiraUserService.getJiraUser();
          this.jiraUserSubscription = this.jiraUserObservable.subscribe({
            next: (users) => {
              this.folderOwner = users;
              this.folderSecondaryOwners = users;
              this.users = users;
            },
            complete: () => {
              this.jiraUserSubscription.unsubscribe();

              this.resetAndPatchForm();
              this.listFolders();
            },
          });
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  listFolders() {
    this.loadingFolders = true;
    this.treeNodeObservable = this.moveItService.getFolderList();
    this.folderSubscription = this.treeNodeObservable.subscribe({
      next: (folders) => {
        this.foldersTree = folders;
      },
      complete: () => {
        this.folderSubscription.unsubscribe();
        this.loadingFolders = false;
      },
    });
  }

  selectFolder(event) {
    this.folderSecondaryOwners = this.users;
    this.selectedFolderSecondaryOwners = [];
    const key = event.node.key;
    if (!environment.production) {
      console.log(event);
      console.log(key);
    }
    this.aktuellerBesitzerObservable = this.ordnerService.currentOwner(
      key,
      false
    );
    let i = 0;
    this.aktuellerBesitzerSubscription = this.aktuellerBesitzerObservable.subscribe(
      {
        next: (resp) => {
          if (!environment.production) {
            console.log(resp);
          }
          if (resp != null) {
            for (const jiraUser of this.folderOwner) {
              if (jiraUser.value.emailAddress == resp.folderOwner) {
                this.selectedFolderOwner = jiraUser;
                break;
              }
            }

            if (
              resp.folderSecondaryOwners != null &&
              resp.folderSecondaryOwners.length > 0
            ) {
              this.selectedFolderSecondaryOwners = [];
              for (const secOwner of this.folderSecondaryOwners) {
                for (const racf of resp.folderSecondaryOwners) {
                  if (racf == secOwner.value.emailAddress) {
                    this.selectedFolderSecondaryOwners[i] = secOwner;
                    i++;
                    const tmpList = [];

                    for (const folderSecondaryOwners of this.users) {
                      let addUser: boolean = true;
                      for (const selectedUser of this
                        .selectedFolderSecondaryOwners) {
                        if (selectedUser.label == folderSecondaryOwners.label) {
                          addUser = false;
                          break;
                        }
                      }
                      if (addUser) {
                        tmpList.push(folderSecondaryOwners);
                      } else {
                        if (!environment.production) {
                          console.log('filter ' + folderSecondaryOwners.label);
                        }
                      }
                    }

                    this.folderSecondaryOwners = tmpList;
                  }
                }
              }
            } else {
              this.selectedFolderSecondaryOwners = [];
            }
          } else {
            this.selectedFolderOwner = null;
            this.selectedFolderSecondaryOwners = [];
          }
        },
        complete: () => {
          i = 0;
          this.aktuellerBesitzerSubscription.unsubscribe();
        },
      }
    );
  }

  private resetAndPatchForm() {
    this.changeOwnerForm.reset();
    this.selectedFolder = null;
    this.selectedFolderOwner = null;
    this.selectedFolderSecondaryOwners = [];
    this.changeOwnerForm.patchValue({
      businessUnit: this.requesterBusinessUnit,
      requester: this.requesterEmail,
    });
  }

  get f() {
    return this.changeOwnerForm.controls;
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  test() {
    if (!environment.production) {
      console.log(this.selectedFolder);
      console.log(this.selectedFolderOwner);
      console.log(this.changeOwnerForm.valid);
    }
  }

  loadUsers() {
    this.jiraUserObservable = this.jiraUserService.getJiraUser();
    this.jiraUserSubscription = this.jiraUserObservable.subscribe({
      next: (users) => {
        this.folderOwner = users;
        this.folderSecondaryOwners = users;
        if (!environment.production) {
          console.log('Users: ' + users);
        }
      },
      complete: () => {
        this.jiraUserSubscription.unsubscribe();
      },
    });
    this.selectedFolderOwner = null;
    this.selectedFolderSecondaryOwners = [];
  }
}
