<div class="bg-transparent" id="background">
  <div class="flex items-center h-screen justify-center">
    <div class="text-center text-blue-900 font-bold">
      <div class="text-ganzgroß leading-tight">
        <div>404</div>
        <div class="text-2xl">
          Da ist etwas schiefgelaufen.
        </div>
      </div>
      <div class="mt-5">
        <a href="/">
          <p-button label="Zur Startseite" class="mr-1 uppercase"></p-button
        ></a>
      </div>
    </div>
  </div>
</div>
