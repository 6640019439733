import {Injectable, OnDestroy} from '@angular/core';
import {MsalBroadcastService as BroadcastService, MsalService} from '@azure/msal-angular';
import {AuthError, EventMessage, EventType} from '@azure/msal-browser';
import {BehaviorSubject, lastValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {delay, filter, retryWhen, take, takeUntil, timeout} from 'rxjs/operators';
import {AuthenticationResult} from '@azure/msal-common';

export interface UserInformation {
  preferredName: string;
  loggedIn: boolean;
  businessUnit: string;
  userInfo: UserInfo;
}

export interface UserInfo {
  email: string;
  racf: string;
  ou: string;
  businessUnit: any;
  admin: boolean;
  keyUser: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserInformationService implements OnDestroy {
  private currentState: UserInformation;
  private msalSubscription: Subscription;
  userInformationObservable: BehaviorSubject<UserInformation>;
  // tslint:disable-next-line:variable-name
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private httpClient: HttpClient
  ) {
    this.init();
  }

  ngOnDestroy() {
    if (this.msalSubscription) {
      this.msalSubscription.unsubscribe();
    }
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  async init() {
    this.currentState = {
      loggedIn: false,
      businessUnit: '',
      preferredName: '',
      userInfo: {
        admin: false,
        businessUnit: '',
        email: '',
        keyUser: false,
        ou: '',
        racf: '',
      },
    };
    this.userInformationObservable = new BehaviorSubject(this.currentState);
    await lastValueFrom(this.authService.initialize())


    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.authService.acquireTokenSilent({scopes: [`${environment.clientId}/.default`], account: this.authService.instance.getAllAccounts()[0]})
      });

    this.broadcastService.msalSubject$
    .pipe(
      // Optional filtering of events.
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      result.payload as AuthenticationResult;
      const payload = result.payload as AuthenticationResult;
      console.log('acquireTokenSuccess: ' , (payload));
      if (!this.currentState.loggedIn) {
        this.currentState = {
          ...this.currentState,
          loggedIn: true,
          preferredName: (payload as any).idTokenClaims.preferred_username,
        };
        this.userInformationObservable.next(this.currentState);

        console.log('preferredName: ' + this.currentState.preferredName);

        if (!this.msalSubscription && this.currentState.businessUnit === '') {
          this.msalSubscription = this.fetchUserInfo().subscribe({
            next: (userInfo: UserInfo) => {
              console.log('userInfo: ' + JSON.stringify(userInfo));
              this.currentState.businessUnit =
                userInfo.businessUnit.businessUnit;
              this.currentState.userInfo = userInfo;
              this.userInformationObservable.next(this.currentState);
            },
            complete: () => {
              this.msalSubscription.unsubscribe();
            },
          });
        } else {
          console.log(
            'businessUnit already known: ' + this.currentState.businessUnit
          );
        }
      }
      // Do something with the result
    });
    /*
    this.broadcastService.subscribe(
      'msal:loginSuccess',
      (payload: AuthResponse) => {
        console.log('loginSuccess');
        this.currentState = {
          ...this.currentState,
          loggedIn: true,
          preferredName: payload.idToken.preferredName,
        };
        this.userInformationObservable.next(this.currentState);
        console.log('preferredName ' + this.currentState.preferredName);

        var subscription: Subscription = this.fetchBusinessUnit().subscribe({
          next: (businessUnit: string) => {
            this.currentState.businessUnit = businessUnit;
            this.userInformationObservable.next(this.currentState);
            console.log('businessUnit ' + this.currentState.businessUnit);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
      }
      );
    */

    this.broadcastService.msalSubject$
      .pipe(
        // Optional filtering of events.
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.error as AuthError;
        console.log('login failure ' + JSON.stringify(payload));
        this.currentState = {
          ...this.currentState,
          loggedIn: false,
          preferredName: 'not-logged-in',
          businessUnit: 'not-logged-in',
        };
        this.userInformationObservable.next(this.currentState);
      });

  }

  get businessUnit(): string {
    return this.currentState.businessUnit;
  }

  get preferredName(): string {
    return this.currentState.preferredName;
  }

  get userInfo(): UserInfo {
    return this.currentState.userInfo;
  }

  private fetchUserInfo(): Observable<UserInfo> {
    return this.httpClient
      .get<UserInfo>(environment.apiUrlPrefix + '/api/user/info')
      .pipe(
        // https://stackoverflow.com/a/44979389/170168
        retryWhen((errors) => errors.pipe(delay(1000), take(10))),
        timeout(60000)
      );
  }
}
