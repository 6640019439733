<p-blockUI [blocked]="blocked">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>

<div *ngIf="userInfo.admin || userInfo.keyUser; else error">
  <div class="bg-white relative">
    <div class="grid gap-4 grid-cols-2 mb-4">
      <div
        class="col-span-2 text-center text-2xl leading-10 border-gray-500 shadow-sm"
        id="headline"
      >
        Administration
      </div>
    </div>
    <div class="grid gap-4 grid-cols-1">
      <div class="col-span-2 w-5/6 mx-auto">
        <p-tabView (onChange)="change($event)" [activeIndex]="index">
          <p-tabPanel header="Gesamtübersicht" *ngIf="userInfo.admin">
            <div class="border-gray-500 rounded-lg shadow-sm">
              <p-chart
                type="bar"
                [data]="data"
                [options]="barOptions"
              ></p-chart>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Tagesübersicht" *ngIf="userInfo.admin">
            <div class="border-gray-500 rounded-lg shadow-sm">
              <p-chart
                type="line"
                [data]="data"
                [options]="lineOptions"
              ></p-chart>
              <div class="flex justify-around m-2 items-center">
                <div class="hidden sm:block md:hidden xl:block">
                  <p-button label="Heute" (onClick)="todayButton()"> </p-button>
                </div>
                <div>
                  Von:

                  <p-calendar
                    [(ngModel)]="dateValueMin"
                    [maxDate]="dateValueMax"
                    dateFormat="dd.mm.yy"
                    (onClose)="getLineChart()"
                  ></p-calendar>
                </div>

                <div>
                  Bis:

                  <p-calendar
                    [(ngModel)]="dateValueMax"
                    [minDate]="dateValueMin"
                    dateFormat="dd.mm.yy"
                    (onClose)="getLineChart()"
                  ></p-calendar>
                </div>
                <div class="hidden sm:block md:hidden xl:block">
                  <p-button label="Reset" (onClick)="resetButton()"> </p-button>
                </div>
              </div>
              <div class="flex justify-around m-2 items-center">
                <div class="block sm:hidden md:block xl:hidden">
                  <p-button label="Heute" (onClick)="todayButton()"> </p-button>
                </div>
                <div class="block sm:hidden md:block xl:hidden">
                  <p-button label="Reset" (onClick)="resetButton()"> </p-button>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Versionsinformation" *ngIf="userInfo.admin">
            <div class="border-gray-500 rounded-lg shadow-sm">
              <p-tabView
                (onChange)="changeVersion($event)"
                [activeIndex]="versionIndex"
              >
                <p-tabPanel header="Backend">
                  <pre>{{ gitVersionBackend }}</pre>
                </p-tabPanel>
                <p-tabPanel header="Email">
                  <pre>{{ gitVersionEmail }}</pre>
                </p-tabPanel>
                <p-tabPanel header="Frontend">
                  <pre>{{ gitVersionFrontend }}</pre>
                </p-tabPanel>
                <p-tabPanel header="Jira">
                  <pre>{{ gitVersionJira }}</pre>
                </p-tabPanel>
                <p-tabPanel header="MOVEit">
                  <pre>{{ gitVersionMoveit }}</pre>
                </p-tabPanel>
                <p-tabPanel header="UserService">
                  <pre>{{ gitVersionUserservice }}</pre>
                </p-tabPanel>
              </p-tabView>
            </div>
          </p-tabPanel>
          <p-tabPanel
            header="Besitzeränderungen"
            *ngIf="userInfo.admin || userInfo.keyUser"
          >
            <div class="border-gray-500 rounded-lg shadow-sm">
              <div style="padding-bottom: 10px;">
                <button
                  type="button"
                  pButton
                  (click)="downloadExcelFile()"
                  style="margin-right: 0.5em; float: right;"
                  icon="pi pi-download"
                  label="Vorlage herunterladen"
                ></button>
                <p-fileUpload
                  #excelFileUpload
                  mode="basic"
                  chooseLabel="Excel Datei hochladen"
                  accept=".xlsx"
                  customUpload="true"
                  [auto]="true"
                  (uploadHandler)="fileUpload($event)"
                ></p-fileUpload>
              </div>
              <div *ngIf="folderOwnershipMassChange?.length > 0">
                <p-table
                  [value]="folderOwnershipMassChange"
                  [(selection)]="selectedFolderOwnershipMassChange"
                  (onRowSelect)="rowSelected($event)"
                  (onRowUnselect)="rowUnselected($event)"
                  (onHeaderCheckboxToggle)="headerCheckboxToggle($event)"
                  dataKey="folderId"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 2.25em;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-mc>
                    <tr>
                      <td>
                        <p-tableCheckbox
                          *ngIf="mc.valid"
                          [value]="mc"
                          [disabled]="mc.valid === false"
                        ></p-tableCheckbox>
                        <div class="self-end" *ngIf="mc.valid === false">
                          <i class="pi pi-exclamation-circle"></i>
                        </div>
                      </td>
                      <td style="width: 90%;">
                        <div *ngIf="mc.folderPath?.length > 0">
                          <span class="header">
                            Verzeichnis:
                          </span>
                          <span>{{ mc.folderPath }} </span>

                          <span *ngIf="mc.folderId?.length > 0"
                            >(ID: {{ mc.folderId }})
                          </span>
                        </div>

                        <div
                          *ngIf="
                            getLabelForJiraUser(mc.folderOwner)?.length > 0
                          "
                        >
                          <span class="header">
                            Besitzer:
                          </span>
                          <span
                            >{{ getLabelForJiraUser(mc.folderOwner) }}
                          </span>
                        </div>

                        <div
                          *ngIf="
                            getLabelForJiraUsers(mc.folderSecondaryOwners)
                              ?.length > 0
                          "
                        >
                          <span class="header">
                            Vertreter:
                          </span>
                          <span
                            >{{
                              getLabelForJiraUsers(mc.folderSecondaryOwners)
                            }}
                          </span>
                        </div>

                        <div
                          *ngIf="
                            getLabelForJiraUser(mc.folderPreviousOwner)
                              ?.length > 0
                          "
                        >
                          <span class="header">
                            bisheriger Besitzer:
                          </span>
                          <span
                            >{{ getLabelForJiraUser(mc.folderPreviousOwner) }}
                          </span>
                        </div>

                        <div
                          *ngIf="
                            getLabelForJiraUsers(
                              mc.folderPreviousSecondaryOwners
                            )?.length > 0
                          "
                        >
                          <span class="header">
                            bisheriger Vertreter:
                          </span>
                          <span
                            >{{
                              getLabelForJiraUsers(
                                mc.folderPreviousSecondaryOwners
                              )
                            }}
                          </span>
                        </div>

                        <div *ngIf="mc.valid === false">
                          <span class="header">
                            Fehler:
                          </span>
                          <span>{{ mc.notValidMessage }}</span>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

                <div style="margin-top: 10px;">
                  <p-button
                    label="Besitzer ändern"
                    (onClick)="requestMassChange($event)"
                    [disabled]="selectedFolderOwnershipMassChange.length === 0"
                  ></p-button>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
      <ng-template #loadingScreen>
        <div class="grid gap-4 grid-cols-2 mb-4">
          <div
            class="col-span-2 text-center leading-10 border-gray-500 shadow-sm"
            id="headline"
          >
            <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<p-toast></p-toast>
<ng-template #error>
  <div class="bg-transparent" id="background">
    <div class="flex items-center h-screen justify-center">
      <div class="text-center text-blue-900 font-bold">
        <div class="text-ganzgroß leading-tight">
          <div>404</div>
          <div class="text-2xl">
            Da ist etwas schiefgelaufen.
          </div>
        </div>
        <div class="mt-5">
          <a href="/">
            <p-button label="Zur Startseite" class="mr-1 uppercase"></p-button
          ></a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
