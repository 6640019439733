
<div>
  <form
    [formGroup]="addGroupForm"
    (ngSubmit)="onSubmit(addGroupForm.value)"
    class="Formular"
    name="addGroupForm"
  >
    <div class="">
      <div class="mb-5">
        <label class="Label" for="businessUnit">
          Business Unit *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="businessUnit"
          type="text"
          placeholder=""
          formControlName="businessUnit"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5">
        <label class="Label" for="requester">
          Anforderer *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="requester"
          type="text"
          placeholder=""
          formControlName="requester"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>
      </div>
      <div class="mb-6">
        <label class="Label" for="groupName">
          Gruppenname *:
        </label>

        <!-- style="width: 295px !important;" -->

        <input
          pInputText
          style="width: 295px !important;"
          class="Textfeld"
          id="groupName"
          type="text"
          placeholder=""
          formControlName="groupName"
          pTooltip=""
          tooltipEvent="focus"
          (ngModelChange)="validateGroupName()"
        />

        <i
          *ngIf="groupAvailable; else groupAlreadyExists"
          class="ui-button-warn pi pi-check align-bottom validIcon text-blue-600"
          pTooltip="Gruppenname verfügbar"
        ></i>

        <ng-template #groupAlreadyExists>
          <i
            *ngIf="groupDouble; else groupNotAvailable"
            pTooltip="Gruppenname schon vorhanden"
            class="ui-button-warn pi pi-times align-bottom validIcon text-red-600"
          ></i>
        </ng-template>

        <ng-template #groupNotAvailable>
          <i
            pTooltip="Gruppenname nicht verfügbar oder zu kurz"
            class="ui-button-warn pi pi-times align-bottom validIcon text-red-600"
          ></i>
        </ng-template>

        <!--
        <button
          pButton
          type="button"
          icon="pi pi-refresh"
          class="ui-button-warn"
          style="margin-left: 5px;"
          (click)="validateGroupName($event)"
          [disabled]="!addGroupForm.valid"
        ></button>
        -->
      </div>

      <div class="mb-5">
        <label
          class="Label"
          style="vertical-align: top;"
          for="groupDescription"
        >
          Beschreibung:
        </label>
        <textarea
          pInputText
          class="Textfeld min-h max-h"
          id="groupDescription"
          type="text"
          placeholder=""
          formControlName="groupDescription"
          pTooltip=""
          tooltipEvent="focus"
        ></textarea>
      </div>

    <div class="mb-5 sm:flex">
      <div class="self-start" style="margin-top: 7px;">
        <div class="Label">
          <label class="" for="groupOwner-dropdown">
            Besitzer *:
          </label>
        </div>
      </div>
      <div class="inline-block">
        <p-dropdown
          name="groupOwner-dropdown"
          id="groupOwner-dropdown"
          [options]="groupOwner"
          [(ngModel)]="selectedGroupOwner"
          [ngModelOptions]="{ standalone: true }"
          showDelay="1000"
          hideDelay="500"
          [filter]="true"
          placeholder=" "
          optionLabel="label"
        ></p-dropdown>
      </div>
      <div class="inline-block self-center ml-2 text-lg">
        <i
          class="pi pi-info-circle"
          showDelay="250"
          hideDelay="250"
          pTooltip="Der Besitzer der Gruppe ist für die Verwaltung der Gruppenmitglieder verantwortlich. Alle Anfragen von Mitarbeitern zu Mitgliedschaften dieser Gruppe müssen vom Besitzer (oder seines Vertreters) über ein Jira-Ticket genehmigt werden."
        ></i>
      </div>
      <!--<p-listbox
            [options]="folderOwner"
            [(ngModel)]="selectedFolderOwner"
            [ngModelOptions]="{ standalone: true }"
            filter="true"
            [listStyle]="{ width: '303px', height: '180px' }"
          >
          </p-listbox>-->
    </div>

    <div class="mb-5 sm:flex">
      <div class="self-start" style="margin-top: 7px;">
        <label class="Label" for="groupSecondaryOwners">
          Vertretung Besitzer:
        </label>
      </div>
      <div class="inline-block">
        <p-pickList
          #pl
          [source]="groupSecondaryOwners"
          [target]="selectedGroupSecondaryOwners"
          name="groupSecondaryOwners"
          id="groupSecondaryOwners"
          sourceHeader="Verfügbare Benutzer"
          targetHeader="Ausgewählte Benutzer"
          filterBy="label"
          [showSourceControls]="false"
          [showTargetControls]="false"
          [sourceStyle]="{ height: '20rem' }"
          [targetStyle]="{ height: '20rem' }"
          emptyMessage="Bitte warten..."
        >
          <ng-template let-user pTemplate="item">
            <div class="ui-helper-clearfix">
              <div>
                {{ user.label }}
              </div>
            </div>
          </ng-template>
        </p-pickList>
        <!--<p-multiSelect                                    // zweiter Entwurf
          name="folderSecondaryOwners-dropdown"
          id="folderSecondaryOwners-dropdown"
          [options]="folderSecondaryOwners"
          formControlName="folderSecondaryOwners"
          showDelay="1000"
          hideDelay="500"
          [filter]="true"
          placeholder=" "
          [showToggleAll]="false"
          [selectedItemsLabel]="this.f.selectedFolderSecondaryOwners + '...'"
        ></p-multiSelect>-->
        <!--<p-listbox                                        // erster Entwurf
          [options]="folderSecondaryOwners"
          [(ngModel)]="selectedFolderSecondaryOwners"
          [ngModelOptions]="{ standalone: true }"
          filter="true"
          [listStyle]="{ width: '303px', height: '180px' }"
        >
        </p-listbox>-->
      </div>
    </div>

      <div class="pl-5 pr-5 m-0 flex">
        <span class="text-gray-600 font-thin text-sm self-end"
          >* Pflichtfelder</span
        >
        <div class="ml-auto">
          <p-button
            [disabled]="!groupAvailable || !addGroupForm.valid"
            label="Gruppe anlegen"
            class="mr-1"
            type="submit"
          ></p-button>
          <p-toast></p-toast>
        </div>
      </div>
  </form>
</div>
