import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { PartialObserver, Subscription } from 'rxjs';
import { environment } from '@selfservice-portal-frontend/environments/environment';
import {
  UserInformation,
  UserInformationService,
} from '../user-information.service';
import { ContactService, SupportTicket } from './service/kontakt.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss'],
})
export class KontaktComponent implements OnInit {
  items: MenuItem[];

  home: MenuItem;

  bus: SelectItem[];

  createContactForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  summary: string;
  requesterEmail: string;
  comment: string;
  type: SelectItem[];
  selectedType: string;
  item: string;

  private userInformationSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private userInformationService: UserInformationService
  ) {
    this.createContactForm = this.formBuilder.group({
      requester: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('task'),
      summary: new UntypedFormControl('', Validators.required),
      comment: new UntypedFormControl(''),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  onSubmit(supportTicket: SupportTicket) {
    const ticketSubscription = this.contactService
      .submitTicket(supportTicket)
      .subscribe({
        next: (_) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('submitTicketRequest Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          ticketSubscription.unsubscribe();
        },
        complete: () => {
          if (!environment.production) {
            console.log('submitTicketRequest Completed');
          }
          ticketSubscription.unsubscribe();
        },
      } as PartialObserver<SupportTicket>);
  }

  ngOnInit(): void {
    this.type = [
      { label: 'Aufgabe', value: 'task' },
      { label: 'Bug', value: 'bug' },
    ];
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Kontakt', routerLink: '/kontakt' }];

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.resetAndPatchForm();
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.userInformationSubscription.unsubscribe();
  }

  private resetAndPatchForm() {
    this.createContactForm.reset();
    this.createContactForm.patchValue({
      requester: this.requesterEmail,
      comment: '',
    });
  }

  get f() {
    return this.createContactForm.controls;
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  get showContactForm(): boolean {
    return environment.showContactForm;
  }
}
