import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

export interface DataSet {
  label: string;
  backgroundColor: string;
  borderColor: string;
  fill: boolean;
  data: number[];
  stack?: string;
}

export interface FolderOwnershipMassChange {
  requester?: string;
  businessUnit: string;
  folderId: string;
  folderPath: string;
  folderOwner: string;
  folderSecondaryOwners?: string[];
  folderPreviousOwner?: string;
  folderPreviousSecondaryOwners?: string[];
  valid: boolean;
  notValidMessage?: string;
  checked: boolean;
}

export interface ParseFolderOwnershipMassChangeResult {
  folderOwnershipMassChanges?: FolderOwnershipMassChange[];
  valid: boolean;
  notValidMessage?: string;
}

export interface FolderOwnershipMassChangeTemplate {
  folderPath: string;
  folderOwner: string;
  folderSecondaryOwners?: string[];
}

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private httpClient: HttpClient) {}

  getLineChart(
    dateValueMin: string,
    dateValueMax: string
  ): Observable<ChartData> {
    console.log(dateValueMax, dateValueMin);
    return this.httpClient
      .get<ChartData>(
        environment.apiUrlPrefix +
          '/api/admin/chart/lineChart/' +
          dateValueMin +
          '/' +
          dateValueMax
      )
      .pipe(retry(3), timeout(1000 * 60));
  }

  getBarChart(): Observable<ChartData> {
    return this.httpClient
      .get<ChartData>(environment.apiUrlPrefix + '/api/admin/chart/barChart')
      .pipe(retry(3), timeout(1000 * 60));
  }

  getVersionInformation(): Observable<Map<string, Map<string, string>>> {
    return this.httpClient
      .get<Map<string, Map<string, string>>>(
        environment.apiUrlPrefix + '/api/admin/versionInfo'
      )
      .pipe(retry(3), timeout(1000 * 60));
  }

  uploadFolderOwnerMassChangeExcel(
    formData: FormData
  ): Observable<ParseFolderOwnershipMassChangeResult> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    return this.httpClient
      .post<ParseFolderOwnershipMassChangeResult>(
        environment.apiUrlPrefix + '/api/admin/folder/owner/parseMassModify',
        formData,
        { headers: headers }
      )
      .pipe(retry(0), timeout(1000 * 60 * 3));
  }

  folderOwnerMassChange(
    massChanges: FolderOwnershipMassChange[]
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        environment.apiUrlPrefix + '/api/admin/folder/owner/massModify',
        massChanges
      )
      .pipe(retry(0), timeout(1000 * 60));
  }
}
