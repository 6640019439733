<div class="bg-white min-h-full p-4">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />
  <div class="m-5">
    <table
      class="border-5 w-full mx-auto max-w-6xl border-separate"
      style="border-spacing: 1rem;"
    >
      <tr>
        <td>
          <a routerLink="/moveit/benutzer">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user hoverboxpi"></i><br />
              Benutzerverwaltung
            </div>
          </a>
        </td>
        <td>
          <a routerLink="/moveit/gruppen">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-users hoverboxpi"></i><br />
              Gruppenverwaltung
            </div>
          </a>
        </td>
        <!--
        <td>
          <a routerLink="/moveit/anlegen">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user-plus hoverboxpi"></i><br />
              Benutzer anlegen
            </div>
          </a>
        </td>
        -->
        <td>
          <a routerLink="/moveit/ordner">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-folder-open hoverboxpi"></i><br />
              Ordnerverwaltung
            </div>
          </a>
        </td>
        <td>
          <a routerLink="/moveit/reports">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-table hoverboxpi"></i><br />
              Reports
            </div>
          </a>
        </td>
      </tr>
      <!--
      <tr>
        <td>
          <a routerLink="#">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user hoverboxpi"></i><br />
              Platzhalter
            </div>
          </a>
        </td>
        <td>
          <a routerLink="#">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user hoverboxpi"></i><br />
              Platzhalter
            </div>
          </a>
        </td>
        <td>
          <a routerLink="#">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user hoverboxpi"></i><br />
              Platzhalter
            </div>
          </a>
        </td>
        <td>
          <a routerLink="#">
            <div
              class="hoverbox hover:-translate-y-1 hover:scale-110 hover:shadow-lg"
            >
              <i class="pi pi-user hoverboxpi"></i><br />
              Platzhalter
            </div>
          </a>
        </td>
      </tr>-->
    </table>
  </div>
</div>
