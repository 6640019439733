import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {TabView} from "primeng/tabview";
import {OrdnerbesitzerComponent} from './ordnerbesitzer.component';
import {OrdnerberechtigungComponent} from './ordnerberechtigung.component';
import {OrdnerAnlegenComponent} from './ordner-anlegen.component';

@Component({
  selector: 'app-ordnerverwaltung',
  templateUrl: './ordnerverwaltung.component.html',
  styleUrls: ['./ordnerverwaltung.component.scss'],
  providers: [],
})
export class OrdnerverwaltungComponent implements OnInit {
  items: MenuItem[];
  home: MenuItem;

  icon1;
  icon2;
  dataLoadedMap;

  @ViewChild(TabView) tabView: TabView;
  @ViewChild(OrdnerbesitzerComponent) ordnerbesitzerComponent: OrdnerbesitzerComponent;
  @ViewChild(OrdnerberechtigungComponent) ordnerberechtigungComponent: OrdnerberechtigungComponent;
  @ViewChild(OrdnerAnlegenComponent) ordnerAnlegenComponent: OrdnerAnlegenComponent;

  constructor() {
  }

  ngOnInit(): void {
    this.home = {icon: 'pi pi-home', routerLink: '/'};
    this.items = [
      {label: 'MOVEit', routerLink: '/moveit'},
      {label: 'Ordnerverwaltung', routerLink: '/moveit/ordner'},
    ];

    // first tab will be loaded automatically
    this.dataLoadedMap = new Map();
    this.dataLoadedMap.set("Ordner anlegen", true);
  }

  get showAddFolderForm(): boolean {
    return environment.showAddFolderForm;
  }
  tabViewChange(event, tabView: TabView) {
    const headerValue = tabView.tabs[event.index].header;

    // ITS-41604: [SSP] Edge zeigt keine Ordner/Gruppen/Benutzerlisten an
    if (this.dataLoadedMap.get(headerValue)) {
      if (!environment.production) {
        console.log("already loaded " + headerValue);
      }
    } else {
      if ("Besitzer ändern" == headerValue) {
        this.ordnerbesitzerComponent.loadUserInformations();
      } else if ("Ordner berechtigen" == headerValue) {
        this.ordnerberechtigungComponent.loadUserInformations();
      } else if ("Ordner anlegen" == headerValue) {
        this.ordnerAnlegenComponent.loadUserInformations();
      }
      this.dataLoadedMap.set(headerValue, true);
    }
  }

}
