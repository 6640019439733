import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import {
  UserInformationService,
  UserInformation,
  UserInfo,
} from '../user-information.service';
import {
  AdminService,
  ChartData,
  FolderOwnershipMassChange,
  ParseFolderOwnershipMassChangeResult,
} from './service/admin.service';
import { Subscription } from 'rxjs';
import { Message, MessageService, SelectItem, TreeNode } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { gitVersion } from 'src/environments/git-version';
import 'chartjs-plugin-colorschemes-v3';
import {
  JiraUser,
  JiraUserService,
} from '../jira.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { MoveItService } from '../moveit/service/moveit.service';
import {
  BesitzerResponse,
  OrdnerService,
} from '../ordnerverwaltung/service/ordner.service';

interface FolderOwnerMassChange {
  folderPath: string;
  folderId?: string;
  owner: string;
  secondaryOwner?: string;
}

interface JiraUserRow {
  username: string;
  realname: string;
}

interface ValidatedJiraUser {
  exists: boolean;
  username: string;
  fixedUsername?: string;
  fixed: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  providers: [AppComponent],
})
export class AdminComponent implements OnInit {
  @ViewChild('excelFileUpload') excelFileUpload: FileUpload;

  successMessage: Message;
  warnMessage: Message;
  failureMessage: Message;

  userInfo: UserInfo;
  data: any;
  barOptions: any;
  lineOptions: any;

  chartType: string = 'barChart';
  dateValue: Date;

  today = new Date();
  lastWeek = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate() - 7
  );
  dateValueMin: Date = this.lastWeek;
  dateValueMax: Date = new Date();

  types: SelectItem[];
  selectedType: string = 'bar';
  index: number = 0;
  chartData: ChartData;

  versionIndex: number = 0;

  versionInfoBackend: string;
  versionInfoMoveit: string;
  versionInfoUserservice: string;
  versionInfoJira: string;
  versionInfoEmail: string;

  blocked: boolean = false;

  jiraUsersMap: any;
  jiraUsers: JiraUser[];
  folderOwnerMap: any;

  folderOwnershipMassChange: FolderOwnershipMassChange[];
  selectedFolderOwnershipMassChange: FolderOwnershipMassChange[];

  private jiraUserSubscription: Subscription;
  private userNameSubscription: Subscription;
  private adminSubscription: Subscription;
  private versionInfoSubscription: Subscription;
  private fileUploadSubscription: Subscription;
  private massChangeSubscription: Subscription;
  private folderSubscription: Subscription;
  private folderOwnerSubscription: Subscription;

  constructor(
    public datepipe: DatePipe,
    private userService: UserInformationService,
    private adminService: AdminService,
    private jiraUserService: JiraUserService,
    private messageService: MessageService,
    private moveItService: MoveItService,
    private ordnerService: OrdnerService
  ) {
    this.types = [
      { label: 'Balkendiagramm', value: 'bar' },
      { label: 'Liniendiagramm', value: 'line' },
    ];

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.warnMessage = {
      severity: 'warn',
      summary: 'Bitte überprüfen.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  ngOnInit(): void {
    this.folderOwnershipMassChange = [];
    this.selectedFolderOwnershipMassChange = [];

    this.userNameSubscription = this.userService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.userInfo = userInformation.userInfo;

          if (this.userInfo.admin) {
            this.getVersionInformation();
            if (this.chartType == 'barChart') {
              this.getBarChart();
            } else if (this.chartType == 'lineChart') {
              this.getLineChart();
            }
          }
        },
        complete: () => {
          this.userNameSubscription.unsubscribe();
        },
      }
    );

    this.jiraUserSubscription = this.jiraUserService.getJiraUser().subscribe({
      next: (users) => {
        this.jiraUsers = users;
        this.jiraUsersMap = new Map<string, JiraUser>();
        for (const user of users) {
          this.jiraUsersMap.set(user.value.emailAddress, user);
        }
      },
      complete: () => {
        this.jiraUserSubscription.unsubscribe();
      },
    });

    this.folderOwnerSubscription = this.ordnerService.folderOwners().subscribe({
      next: (folderOwners) => {
        this.folderOwnerMap = new Map<string, BesitzerResponse>();
        for (const owner of folderOwners) {
          this.folderOwnerMap.set(owner.folderId, owner);
        }
      },
      complete: () => {
        this.folderOwnerSubscription.unsubscribe();
      },
    });

    this.barOptions = {
      legend: {
        position: 'bottom',
      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.Tableau20',
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
          },
        ],
      },
    };

    this.lineOptions = {
      legend: {
        position: 'bottom',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
          },
        ],
      },
    };
  }

  changeVersion(e) {
    // empty
  }

  change(e) {
    this.data = null;
    const index = e.index;
    if (index == 0) {
      this.barChart();
    } else if (index == 1) {
      this.lineChart();
    }
  }

  barChart() {
    this.chartType = 'barChart';
    this.getBarChart();
  }

  lineChart() {
    this.chartType = 'lineChart';
    this.dateValueMin = this.lastWeek;
    this.dateValueMax = this.today;
    this.getLineChart();
  }

  getLineChart() {
    const dateValueMin = this.datepipe.transform(this.dateValueMin, 'yyyy-MM-dd');
    const dateValueMax = this.datepipe.transform(this.dateValueMax, 'yyyy-MM-dd');

    this.adminSubscription = this.adminService
      .getLineChart(dateValueMin, dateValueMax)
      .subscribe({
        next: (chartData: ChartData) => {
          let i = 0;
          while (i < chartData.datasets.length) {
            chartData.datasets[i].fill = false;
            i++;
          }
          this.data = chartData;
          this.chartData = chartData;
        },
        complete: () => {
          this.adminSubscription.unsubscribe();
        },
      });
  }

  getBarChart() {
    this.adminSubscription = this.adminService.getBarChart().subscribe({
      next: (chartData: ChartData) => {
        this.chartData = chartData;
        this.data = chartData;
      },
      complete: () => {
        this.adminSubscription.unsubscribe();
      },
    });
  }

  getVersionInformation() {
    this.versionInfoSubscription = this.adminService
      .getVersionInformation()
      .subscribe({
        next: (versionInfo: Map<string, Map<string, string>>) => {
          const map = new Map(Object.entries(versionInfo));

          this.versionInfoBackend = JSON.stringify(
            map.get('selfservice-portal-backend'),
            null,
            2
          );

          this.versionInfoEmail = JSON.stringify(
            map.get('selfservice-portal-email'),
            null,
            2
          );

          this.versionInfoJira = JSON.stringify(
            map.get('selfservice-portal-jira'),
            null,
            2
          );

          this.versionInfoMoveit = JSON.stringify(
            map.get('selfservice-portal-moveit'),
            null,
            2
          );

          this.versionInfoUserservice = JSON.stringify(
            map.get('selfservice-portal-userservice'),
            null,
            2
          );
        },
        complete: () => {
          this.versionInfoSubscription.unsubscribe();
        },
      });
  }

  todayButton() {
    const today = new Date();
    this.dateValueMin = today;
    this.dateValueMax = today;
    this.getLineChart();
  }

  resetButton() {
    this.dateValueMin = this.lastWeek;
    this.dateValueMax = this.today;
    this.getLineChart();
  }

  get gitVersionFrontend(): string {
    return JSON.stringify(gitVersion, null, 2);
  }

  get gitVersionBackend(): string {
    return this.versionInfoBackend;
  }

  get gitVersionEmail(): string {
    return this.versionInfoEmail;
  }

  get gitVersionJira(): string {
    return this.versionInfoJira;
  }

  get gitVersionUserservice(): string {
    return this.versionInfoUserservice;
  }

  get gitVersionMoveit(): string {
    return this.versionInfoMoveit;
  }

  fileUpload(event: any) {
    this.blocked = true;
    this.folderOwnershipMassChange = [];
    this.selectedFolderOwnershipMassChange = [];
    const formData = new FormData();
    formData.append('uploadFile', event.files[0]);
    this.fileUploadSubscription = this.adminService
      .uploadFolderOwnerMassChangeExcel(formData)
      .subscribe({
        next: (result: ParseFolderOwnershipMassChangeResult) => {
          this.folderOwnershipMassChange = result.folderOwnershipMassChanges;
          let count = 0;
          for (const mc of this.folderOwnershipMassChange) {
            this.validateMassChange(mc);
            if (!mc.valid) {
              count++;
            }
          }
          if (count > 0) {
            this.messageService.add(
              this.warnMessageToast(count + ' ungültige Zeile(n) gefunden.')
            );
          }
          if (!result.valid) {
            this.messageService.add(
              this.warnMessageToast(result.notValidMessage)
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.messageService.add(this.failureMessageToast(err.message));
          this.uploadFinished();
        },
        complete: () => {
          this.uploadFinished();
        },
      });
  }

  private validateMassChange(mc: FolderOwnershipMassChange) {
    if (mc.notValidMessage == null) {
      mc.notValidMessage = ' ';
    } else {
      mc.notValidMessage += ' ';
    }

    const validatedJiraUser = this.validateJiraUser(mc.folderOwner);
    if (!validatedJiraUser.exists) {
      mc.valid = false;
      if (mc.folderOwner != null && mc.folderOwner.length > 0) {
        mc.notValidMessage += mc.folderOwner + ' ist unbekannt. ';
      }
    } else {
      mc.folderOwner = validatedJiraUser.fixedUsername;
    }

    if (mc.folderSecondaryOwners != null) {
      for (let i = 0; i < mc.folderSecondaryOwners.length; i++) {
        const validatedJiraUser = this.validateJiraUser(
          mc.folderSecondaryOwners[i]
        );
        if (!validatedJiraUser.exists) {
          mc.valid = false;
          if (
            mc.folderSecondaryOwners[i] != null &&
            mc.folderSecondaryOwners[i].length > 0
          ) {
            mc.notValidMessage +=
              mc.folderSecondaryOwners[i] + ' ist unbekannt. ';
          }
        } else {
          mc.folderSecondaryOwners[i] = validatedJiraUser.fixedUsername;
        }
      }
    }

    if (mc.folderId == null) {
      mc.valid = false;
      mc.notValidMessage += ' Ordner ID fehlt. ';
    }

    if (
      mc.folderOwner == mc.folderPreviousOwner &&
      JSON.stringify(mc.folderSecondaryOwners) ===
        JSON.stringify(mc.folderPreviousSecondaryOwners)
    ) {
      mc.valid = false;
      mc.notValidMessage += ' Keine Änderung an Besitzer und Vertreter. ';
    }

    mc.notValidMessage = mc.notValidMessage.trim();
    // console.log(mc);
  }

  private validateJiraUser(user: string): ValidatedJiraUser {
    const validatedJiraUser: ValidatedJiraUser = {
      exists: false,
      username: user,
      fixed: false,
    };

    if (user != null) {
      if (this.jiraUsersMap.get(user) != null) {
        validatedJiraUser.fixedUsername = user;
        validatedJiraUser.exists = true;
      } else if (this.jiraUsersMap.get(user.toLowerCase()) != null) {
        validatedJiraUser.fixedUsername = user.toLowerCase();
        validatedJiraUser.exists = true;
        validatedJiraUser.fixed = true;
      } else if (this.jiraUsersMap.get(user.toUpperCase()) != null) {
        validatedJiraUser.fixedUsername = user.toUpperCase();
        validatedJiraUser.exists = true;
        validatedJiraUser.fixed = true;
      }
    }

    if (!validatedJiraUser.exists || validatedJiraUser.fixed) {
      console.log('validateJiraUser ' + JSON.stringify(validatedJiraUser));
    }

    return validatedJiraUser;
  }

  private uploadFinished() {
    this.fileUploadSubscription.unsubscribe();
    this.excelFileUpload.clear();
    this.blocked = false;
  }

  requestMassChange(event: any) {
    this.blocked = true;
    this.massChangeSubscription = this.adminService
      .folderOwnerMassChange(this.selectedFolderOwnershipMassChange)
      .subscribe({
        next: () => {
          this.messageService.add(this.successMessage);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.messageService.add(this.failureMessageToast(err.message));
          this.massModifyFinished();
        },
        complete: () => {
          this.massModifyFinished();
        },
      });
  }

  private massModifyFinished() {
    this.massChangeSubscription.unsubscribe();
    this.folderOwnershipMassChange = [];
    this.selectedFolderOwnershipMassChange = [];
    this.excelFileUpload.clear();
    this.blocked = false;
  }

  rowSelected(event: any) {
    // console.log('select ' + event.data);
    // console.log(this.selectedFolderOwnershipMassChange);
  }

  rowUnselected(event: any) {
    // console.log('unselect ' + event.data);
    // console.log(this.selectedFolderOwnershipMassChange);
  }

  headerCheckboxToggle(event: any) {
    this.blocked = true;
    this.selectedFolderOwnershipMassChange = [];
    if (event.checked) {
      for (const mc of this.folderOwnershipMassChange) {
        if (mc.valid) {
          this.selectedFolderOwnershipMassChange.push(mc);
        }
      }
    }
    this.blocked = false;
  }

  getLabelForJiraUser(user: string): string {
    if (user != null && this.jiraUsersMap.get(user) != null) {
      return this.jiraUsersMap.get(user).label;
    }
    return '';
  }

  getLabelForJiraUsers(users: string[]): string {
    let result = '';
    if (users != null) {
      for (const user of users) {
        result = result + ' ' + this.getLabelForJiraUser(user);
      }
    }
    return result.trim();
  }

  private warnMessageToast(text: string): Message {
    return {
      ...this.warnMessage,
      detail: `${text}`,
    };
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  private parseFolderTree(rows: FolderOwnerMassChange[], folders: TreeNode[]) {
    for (const folder of folders) {
      const owner = this.folderOwnerMap.get(folder.key);
      const folderOwnerMassChange: FolderOwnerMassChange = {
        folderPath: folder.data,
        folderId: folder.key,
        owner: owner != null ? owner.folderOwner : '',
        secondaryOwner:
          owner != null ? owner.folderSecondaryOwners.toString() : '',
      };
      rows.push(folderOwnerMassChange);
      if (folder.children && folder.children.length > 0) {
        this.parseFolderTree(rows, folder.children);
      }
    }
  }

  downloadExcelFile() {
    this.blocked = true;
    import('xlsx').then((xlsx) => {
      this.folderSubscription = this.moveItService.getFolderList().subscribe({
        next: (folders: TreeNode[]) => {
          const workbook = xlsx.utils.book_new();

          // Ordnerbesitzer
          const folderOwnerRows: FolderOwnerMassChange[] = [];
          folderOwnerRows.push({
            folderPath: 'Ordner',
            folderId: 'Ordner ID',
            owner: 'Besitzer (Jira User) neu',
            secondaryOwner: 'Vertreter (Jira User) neu',
          });
          this.parseFolderTree(folderOwnerRows, folders);
          const ownerSheet = xlsx.utils.json_to_sheet(folderOwnerRows, {
            skipHeader: true,
          });
          xlsx.utils.book_append_sheet(workbook, ownerSheet, 'Ordnerbesitzer');

          // Jira Benutzer
          const jiraUserRows: JiraUserRow[] = [];
          jiraUserRows.push({
            realname: 'Realname',
            username: 'Username',
          });

          for (const jiraUser of this.jiraUsers) {
            jiraUserRows.push({
              realname: jiraUser.value.realname,
              username: jiraUser.value.emailAddress,
            });
          }
          const jiraUserSheet = xlsx.utils.json_to_sheet(jiraUserRows, {
            skipHeader: true,
          });
          xlsx.utils.book_append_sheet(
            workbook,
            jiraUserSheet,
            'Jira Benutzer'
          );

          const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });
          this.saveAsExcelFile(excelBuffer, 'folder-owner-masschange-template');
        },
        complete: () => {
          this.folderSubscription.unsubscribe();
        },
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const data: Blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      this.blocked = false;
      const timestamp = this.datepipe.transform(new Date(), 'yyyyMMdd-HHmmss');
      FileSaver.default(data, fileName + '-' + timestamp + '.xlsx');
    });
  }
}
