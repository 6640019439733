import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {MenuItem, Message, MessageService, SelectItem,} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItGroup, MoveItService} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {JiraUser, JiraUserService} from '../jira.service';
import {
  BesitzerResponse,
  GruppenBesitzerRequest,
  GruppenBesitzerResponse,
  GruppenverwaltungService,
} from './service/gruppenverwaltung.service';

@Component({
  selector: 'app-gruppenbesitzer',
  templateUrl: './gruppenbesitzer.component.html',
  styleUrls: ['./gruppenbesitzer.component.scss'],
})
export class GruppenbesitzerComponent implements OnInit {

  groupList: MoveItGroup[];
  items: MenuItem[];
  home: MenuItem;
  bus: SelectItem[];
  changeOwnerForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  group: SelectItem[];
  groupOwner: JiraUser[];
  groupSecondaryOwners: JiraUser[];

  selectedGroup: MoveItGroup;
  selectedGroupOwner: JiraUser;
  selectedGroupSecondaryOwners: JiraUser[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  users: JiraUser[];

  _blocked: boolean = false;

  get blocked(): boolean {
    return this._blocked;
  }

  set blocked(value: boolean) {
    this._blocked = value;
    this.loading.emit(this._blocked);
  }

  @Output() loading = new EventEmitter<boolean>();


  icon;

  private jiraUserSubscription: Subscription;
  private jiraUserObservable: Observable<JiraUser[]>;

  private userInformationSubscription: Subscription;

  private aktuellerBesitzerSubscription: Subscription;
  private aktuellerBesitzerObservable: Observable<BesitzerResponse>;

  constructor(
    private moveItService: MoveItService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private gruppenverwaltungService: GruppenverwaltungService,
    private jiraUserService: JiraUserService,
    private userInformationService: UserInformationService
  ) {

    this.changeOwnerForm = this.formBuilder.group({
      businessUnit: new UntypedFormControl('', Validators.required),
      requester: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl(''),
      groupOwner: new UntypedFormControl(''),
      groupSecondaryOwners: new UntypedFormControl(''),
      selectedGroupOwner: new UntypedFormControl(''),
      selectedGroupSecondaryOwners: new UntypedFormControl(''),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  private moveItGroupObservable: Observable<MoveItGroup[]>;

  private groupSubscription: Subscription;

  onSubmit(createFolderRequest: GruppenBesitzerRequest) {
    this.blocked = true;
    const groupSecondaryOwnersList: string[] = [];

    if (this.selectedGroupSecondaryOwners != null) {
      this.selectedGroupSecondaryOwners.forEach((selectedSecondaryOwner) => {
        groupSecondaryOwnersList.push(selectedSecondaryOwner.value.emailAddress);
      });
    }

    const req: GruppenBesitzerRequest = {
      businessUnit: this.changeOwnerForm.get('businessUnit').value,
      requester: this.changeOwnerForm.get('requester').value,
      groupId: this.selectedGroup.value.id,
      groupName: this.selectedGroup.value.name,
      groupOwner: this.selectedGroupOwner.value.emailAddress,
      groupSecondaryOwners: groupSecondaryOwnersList,
    };

    if (!environment.production) {
      console.log(req);
    }

    const besitzerAendernSubscription = this.gruppenverwaltungService
      .changeOwner(req)
      .subscribe({
        next: (_) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          console.log('changeFolderOwnerRequest Error');
          this.messageService.add(this.failureMessageToast(err.message));
          besitzerAendernSubscription.unsubscribe();
          this.blocked = false;
        },
        complete: () => {
          console.log('changeFolderOwnerRequest Completed');
          besitzerAendernSubscription.unsubscribe();
          this.blocked = false;
        },
      } as PartialObserver<GruppenBesitzerResponse>);
    this.loadUsers();
  }

  ngOnInit() {
    this.groupList = [{label: 'Bitte warten...', value: null}];
    // this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  listGroups() {
    this.moveItGroupObservable = this.moveItService.getGroupList();
    this.groupSubscription = this.moveItGroupObservable.subscribe({
      next: (groups) => {
        this.groupList = groups;
      },
      complete: () => {
        this.groupSubscription.unsubscribe();
        this.blocked = false;
      },
    });
  }

  selectGroup(event) {
    this.groupSecondaryOwners = this.users;
    this.selectedGroupSecondaryOwners = [];
    const key = event.value.value.id;
    if (!environment.production) {
      console.log(event);
      console.log(key);
    }
    this.aktuellerBesitzerObservable = this.gruppenverwaltungService.currentOwner(
      key,
      false
    );
    let i = 0;
    this.aktuellerBesitzerSubscription = this.aktuellerBesitzerObservable.subscribe(
      {
        next: (resp) => {
          if (!environment.production) {
            console.log(resp);
          }
          if (resp != null) {
            for (const jiraUser of this.groupOwner) {
              if (jiraUser.value.emailAddress == resp.groupOwner) {
                this.selectedGroupOwner = jiraUser;
                break;
              }
            }

            if (
              resp.groupSecondaryOwners != null &&
              resp.groupSecondaryOwners.length > 0
            ) {
              this.selectedGroupSecondaryOwners = [];
              for (const secOwner of this.groupSecondaryOwners) {
                for (const racf of resp.groupSecondaryOwners) {
                  if (racf == secOwner.value.emailAddress) {
                    this.selectedGroupSecondaryOwners[i] = secOwner;
                    i++;
                    const tmpList = [];

                    for (const groupSecondaryOwners of this.users) {
                      let addUser: boolean = true;
                      for (const selectedUser of this
                        .selectedGroupSecondaryOwners) {
                        if (selectedUser.label == groupSecondaryOwners.label) {
                          addUser = false;
                          break;
                        }
                      }
                      if (addUser) {
                        tmpList.push(groupSecondaryOwners);
                      } else {
                        if (!environment.production) {
                          console.log('filter ' + groupSecondaryOwners.label);
                        }
                      }
                    }

                    this.groupSecondaryOwners = tmpList;
                  }
                }
              }
            } else {
              this.selectedGroupSecondaryOwners = [];
            }
          } else {
            this.selectedGroupOwner = null;
            this.selectedGroupSecondaryOwners = [];
          }
        },
        complete: () => {
          i = 0;
          this.aktuellerBesitzerSubscription.unsubscribe();
        },
      }
    );
  }

  private resetAndPatchForm() {
    this.changeOwnerForm.reset();
    this.selectedGroup = null;
    this.selectedGroupOwner = null;
    this.selectedGroupSecondaryOwners = [];
    this.changeOwnerForm.patchValue({
      businessUnit: this.requesterBusinessUnit,
      requester: this.requesterEmail,
    });
  }

  get f() {
    return this.changeOwnerForm.controls;
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  test() {
    if (!environment.production) {
      console.log(this.selectedGroup);
      console.log(this.selectedGroupOwner);
      console.log(this.changeOwnerForm.valid);
    }
  }

  loadUsers() {
    this.jiraUserObservable = this.jiraUserService.getJiraUser();
    this.jiraUserSubscription = this.jiraUserObservable.subscribe({
      next: (users) => {
        this.groupOwner = users;
        this.groupSecondaryOwners = users;
      },
      complete: () => {
        this.jiraUserSubscription.unsubscribe();
      },
    });
    this.selectedGroupOwner = null;
    this.selectedGroupSecondaryOwners = [];
  }

  loadUserInformations() {
    this.blocked = true;
    this.groupOwner = [{label: 'Bitte warten...', value: null}];
    this.groupSecondaryOwners = [{label: 'Bitte warten...', value: null}];

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.requesterBusinessUnit = userInformation.businessUnit;

          this.jiraUserObservable = this.jiraUserService.getJiraUser();
          this.jiraUserSubscription = this.jiraUserObservable.subscribe({
            next: (users) => {
              this.groupOwner = users;
              this.groupSecondaryOwners = users;
              this.users = users;
            },
            complete: () => {
              this.jiraUserSubscription.unsubscribe();

              this.resetAndPatchForm();
              this.listGroups();
            },
          });
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

}
