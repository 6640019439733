import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {MenuItem, Message, MessageService, SelectItem, TreeNode,} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItService} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {JiraUser, JiraUserService} from '../jira.service';
import {FolderAccess, OrdnerAnlegenRequest, OrdnerAnlegenResponse, OrdnerService,} from './service/ordner.service';

@Component({
  selector: 'app-ordner-anlegen',
  templateUrl: './ordner-anlegen.component.html',
  styleUrls: ['./ordner-anlegen.component.scss'],
})
export class OrdnerAnlegenComponent implements OnInit {
  foldersTree: TreeNode[];
  folderSet: any;

  items: MenuItem[];

  home: MenuItem;

  bus: SelectItem[];

  addFolderForm: UntypedFormGroup;

  successMessage: Message;
  failureMessage: Message;

  folderParent: SelectItem[];
  folderName: string;
  folderOwner: JiraUser[];
  folderSecondaryOwners: JiraUser[];

  selectedFolder: any;
  selectedFolderParent: TreeNode;
  selectedFolderOwner: JiraUser;
  selectedFolderSecondaryOwners: JiraUser[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  users: JiraUser[];
  loadingFolders: boolean;
  loadingUsers: boolean;

  neuerPfad: string;
  folderAvailable: boolean = false;
  icon;

  private jiraUserSubscription: Subscription;
  private jiraUserObservable: Observable<JiraUser[]>;

  private userInformationSubscription: Subscription;

  constructor(
    private moveItService: MoveItService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private ordnerService: OrdnerService,
    private jiraUserService: JiraUserService,
    private userInformationService: UserInformationService
  ) {
    const regEx = /^[-a-zA-Z0-9-(\-_§!$=@,.+#)]+(\s+[-a-zA-Z0-9-(\-_§!$=@,.+#)]+)*$/; // Whitespace am Anfang des Strings nicht erlaubt + einige Sonderzeichen

    this.addFolderForm = this.formBuilder.group({
      businessUnit: new UntypedFormControl('', Validators.required),
      requester: new UntypedFormControl('', Validators.required),
      folderName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(regEx),
      ]),
      folderOwner: new UntypedFormControl(''),
      folderParent: new UntypedFormControl(''),
      folderSecondaryOwners: new UntypedFormControl(''),
      selectedFolderOwner: new UntypedFormControl(''),
      selectedFolderSecondaryOwners: new UntypedFormControl(''),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  private treeNodeObservable: Observable<TreeNode[]>;

  private folderSubscription: Subscription;

  onSubmit(createFolderRequest: OrdnerAnlegenRequest) {
    const folderSecondaryOwnersList: string[] = [];

    if (this.selectedFolderSecondaryOwners != null) {
      this.selectedFolderSecondaryOwners.forEach((selectedSecondaryOwner) => {
        folderSecondaryOwnersList.push(selectedSecondaryOwner.value.emailAddress);
      });
    }

    const req: OrdnerAnlegenRequest = {
      businessUnit: this.addFolderForm.get('businessUnit').value,
      requester: this.addFolderForm.get('requester').value,
      folderParent: this.selectedFolderParent.data,
      folderName: this.addFolderForm.get('folderName').value,
      folderOwner: this.selectedFolderOwner.value.emailAddress,
      folderSecondaryOwners: folderSecondaryOwnersList,
    };
    if (!environment.production) {
      console.log(req);
    }

    const ordnerAnlegenSubscription = this.ordnerService
      .createFolder(req)
      .subscribe({
        next: (_) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('createFolderRequest Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          ordnerAnlegenSubscription.unsubscribe();
        },
        complete: () => {
          if (!environment.production) {
            console.log('createFolderRequest Completed');
          }
          ordnerAnlegenSubscription.unsubscribe();
        },
      } as PartialObserver<OrdnerAnlegenResponse>);
    this.loadUsers();
  }

  private folderAccessSubscription: Subscription;

  ngOnInit() {
    this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  loadUserInformations() {
    this.loadingUsers = true;
    this.loadingFolders = true;
    this.folderOwner = [{label: 'Bitte warten...', value: null}];
    this.folderSecondaryOwners = [{label: 'Bitte warten...', value: null}];

    this.foldersTree = [];
    this.folderSet = new Set();

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.requesterBusinessUnit = userInformation.businessUnit;

          this.jiraUserObservable = this.jiraUserService.getJiraUser();
          this.jiraUserSubscription = this.jiraUserObservable.subscribe({
            next: (users) => {
              this.folderOwner = users;
              this.folderSecondaryOwners = users;
              this.loadingUsers = false;
            },
            complete: () => {
              this.jiraUserSubscription.unsubscribe();

              this.resetAndPatchForm();
              this.listFolders();
            },
          });
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  listFolders() {
    this.loadingFolders = true;
    this.treeNodeObservable = this.moveItService.getFolderList();
    this.folderSubscription = this.treeNodeObservable.subscribe({
      next: (folders) => {
        this.foldersTree = folders;
        this.folderSet = new Set();
        this.buildFolderSet(this.foldersTree);
        this.loadingFolders = false;
      },
      complete: () => {
        this.folderSubscription.unsubscribe();
      },
    });
  }

  buildFolderSet(folders: TreeNode[]) {
    if (folders != null) {
      folders.forEach((folder) => {
        this.folderSet.add(folder.data.toLowerCase());
        this.buildFolderSet(folder.children);
      });
    }
  }

  selectFolder(event) {
    this.validateFolderName();
  }

  private resetAndPatchForm() {
    this.addFolderForm.reset();
    this.selectedFolderOwner = null;
    this.selectedFolderParent = null;
    this.selectedFolderSecondaryOwners = [];
    this.addFolderForm.patchValue({
      businessUnit: this.requesterBusinessUnit,
      requester: this.requesterEmail,
    });
  }

  validateFolderName() {
    if (
      this.selectedFolderParent != null &&
      this.addFolderForm.get('folderName').value != null
    ) {
      this.neuerPfad =
        this.selectedFolderParent.data +
        '/' +
        this.addFolderForm.get('folderName').value;
      if (this.folderSet.has(this.neuerPfad.toLowerCase())) {
        this.folderAvailable = false;
      } else {
        this.folderAvailable = true;
      }
    }
  }

  get f() {
    return this.addFolderForm.controls;
  }

  private mapFolderAccess(folderAccess: FolderAccess) {
    // empty
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  test($event) {
    if (!environment.production) {
      console.log(this.selectedFolderSecondaryOwners);
    }
  }

  loadUsers() {
    this.jiraUserObservable = this.jiraUserService.getJiraUser();
    this.jiraUserSubscription = this.jiraUserObservable.subscribe({
      next: (users) => {
        this.folderOwner = users;
        this.folderSecondaryOwners = users;
        if (!environment.production) {
          console.log('Users: ' + users);
        }
      },
      complete: () => {
        this.jiraUserSubscription.unsubscribe();
      },
    });
    this.selectedFolderOwner = null;
    this.selectedFolderSecondaryOwners = [];
  }


}
