<p-blockUI [blocked]="blocked || loadingFolders || loadingOwner">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>

<p-confirmDialog #cd header="Bitte bestätigen!" icon="pi pi-exclamation-circle">
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Ja"
      (click)="cd.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="Nein"
      (click)="cd.reject(); parentInheritRights = true"
      style="
        background-color: rgb(118, 118, 118);
        border-color: rgb(118, 118, 118);
      "
    ></button>
  </p-footer>
</p-confirmDialog>

<div class="">
  <div class="Formular">
    <div class="mb-1">
      <div class="grid lg:grid-cols-3 gap-5">
        <div>
          <div>
            <div style="padding: 10px; padding-bottom: 11px;">
              <span class="text-gray-800 text-lg font-bold mb-2">Ordner:</span>
            </div>
            <div style="padding: 16px; padding-bottom: 0px;">
              <p-tree
                [value]="foldersTree"
                [filter]="true"
                scrollHeight="325px"
                filterPlaceholder="Suchbegriff (mindestens 2 Buchstaben)"
                selectionMode="single"
                [(selection)]="selectedFolder"
                class="tree"
                [style]="{ height: '410px' }"
                [loading]="loadingFolders || loadingPermissions"
                (onNodeSelect)="selectFolder($event)"
              ></p-tree>
              <span
                ><p-progressBar
                  [style]="{ height: '6px', 'background-color': 'white' }"
                  mode="indeterminate"
                  *ngIf="loadingFolders"
                ></p-progressBar
              ></span>
            </div>
          </div>
        </div>

        <div>
          <p-tabView (onChange)="handleTabChange($event)">
            <p-tabPanel header="Benutzer">
              <div class="tree">
                <p-listbox
                  [options]="users"
                  [(ngModel)]="selectedUser"
                  [filter]="true"
                  [filterFields]="['label', 'value.email']"
                  [listStyle]="{ height: '350px' }"
                  (onClick)="selectUser($event)"
                  emptyMessage="Bitte warten..."
                ></p-listbox></div
            ></p-tabPanel>
            <p-tabPanel header="Gruppen">
              <div class="tree">
                <p-listbox
                  [options]="groups"
                  [(ngModel)]="selectedGroup"
                  [filter]="true"
                  [filterFields]="['label', 'value.email']"
                  [listStyle]="{ height: '350px' }"
                  (onClick)="selectGroup($event)"
                  emptyMessage="Bitte warten..."
                ></p-listbox></div
            ></p-tabPanel>
            <span
              ><p-progressBar
                [style]="{ height: '6px', 'background-color': 'white' }"
                mode="indeterminate"
                *ngIf="loadingUsersGroups"
              ></p-progressBar
            ></span>
          </p-tabView>
        </div>

        <div>
          <div style="padding: 10px; padding-bottom: 11px;">
            <span class="text-gray-800 text-lg font-bold mb-2">
              Ordnereinstellungen:
            </span>
          </div>

          <div style="padding: 16px; padding-bottom: 0px;">
            <div class="checkboxbox flex flex-col justify-around">
              <div class="ui-g-8 inline-block flex">
                <div class="self-center mr-1">
                  <p-checkbox
                    name="parentInheritRights"
                    label="Inherit Access From Parent "
                    [(ngModel)]="parentInheritRights"
                    [binary]="true"
                    (onChange)="changeParentInheritRights($event)"
                    [disabled]="
                      loadingPermissions ||
                      loadingFolders ||
                      loadingUsersGroups ||
                      selectedFolder == null ||
                      !parentInheritRightsCheckboxEnabled
                    "
                  ></p-checkbox>
                </div>
                <div class="self-end">
                  <i
                    class="pi pi-info-circle"
                    showDelay="250"
                    hideDelay="250"
                    pTooltip="Es gelten die Berechtigungen vom übergeordnetem Ordner. Diese Einstellung kann nachträglich nicht wieder über das SSP aktiviert werden!"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="grid lg:grid-cols-1 gap-5">
            <div class="p-col">
              <div style="padding: 10px; padding-bottom: 11px;">
                <span class="text-gray-800 text-lg font-bold mb-2">
                  Berechtigungen:
                </span>
              </div>

              <div style="padding: 16px; padding-bottom: 0px;">
                <div
                  class="checkboxbox flex flex-col justify-around"
                  style="height: 300px;"
                >
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="read"
                        value="R"
                        label="Lesen (R) "
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="Der Benutzer bzw. die Gruppe kann Dateien aus diesem Ordner öffnen/lesen."
                      ></i>
                    </div>
                  </div>
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="write"
                        value="W"
                        label="Schreiben (W) "
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="Der Benutzer bzw. die Gruppe kann Dateien in diesem Ordner hochladen/sie überschreiben."
                      ></i>
                    </div>
                  </div>
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="delete"
                        value="D"
                        label="Löschen (D) "
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="Der Benutzer bzw. die Gruppe kann Dateien aus diesem Ordner löschen."
                      ></i>
                    </div>
                  </div>
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="list"
                        value="L"
                        label="Liste (L) "
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="Der Benutzer bzw. die Gruppe kann alle Dateien, die zu diesem Ordner hinzugefügt werden, sehen."
                      ></i>
                    </div>
                  </div>
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="notify"
                        value="N"
                        label="Benachrichtigen (N) "
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="E-Mail Benachrichtigungen werden an den Benutzer bzw. die Gruppe versendet, wenn neue Dateien in diesen Ordner hochgeladen wurden (Ausnahme: Dateien in eigenem Ordner)."
                      ></i>
                    </div>
                  </div>
                  <div class="ui-g-8 inline-block flex">
                    <div class="self-center mr-1">
                      <p-checkbox
                        name="Subfolders (E) "
                        value="E"
                        label="Unterordner"
                        [(ngModel)]="selectedCheckbox"
                        [disabled]="accessrightCheckboxDisabled"
                      ></p-checkbox>
                    </div>
                    <div class="self-end">
                      <i
                        class="pi pi-info-circle"
                        showDelay="250"
                        hideDelay="250"
                        pTooltip="Der Benutzer bzw. die Gruppe kann Unterordner in diesem Ordner hinzufügen und umbenennen und entfernen."
                      ></i>
                    </div>
                    <!--
              <div class="ui-g-8 inline-block flex">
                <div class="self-center mr-1">
                <p-checkbox
                  name="admin"
                  value="A"
                  label="Admin (A) "
                  [(ngModel)]="selectedCheckbox"
                  [disabled]="accessrightCheckboxDisabled"
                ></p-checkbox>
                <i
                  class="pi pi-info-circle"
                  showDelay="1000"
                  hideDelay="500"
                  pTooltip="Hier fehlt auch noch Text."
                ></i>
              </div>
              </div>
              -->
                  </div>
                </div>
              </div>
              <span
                ><p-progressBar
                  [style]="{
                    height: '6px',
                    width: '192px',
                    'background-color': 'white',
                    'margin-left': '16px'
                  }"
                  mode="indeterminate"
                  *ngIf="loadingPermissions"
                ></p-progressBar
              ></span>
            </div>

            <!-- Ordnerbesitzer (start) -->
            <!--
            <div class="p-col">
              <div style="padding: 10px; padding-bottom: 11px;">
                <span class="text-gray-800 text-lg font-bold mb-2">
                  Ordnerbesitzer:
                </span>
              </div>

              <div style="padding: 16px; padding-bottom: 0px;">
                <div
                  class="checkboxbox flex flex-col justify-around"
                  style="height: 300px;"
                >
                  <div class="ui-g-8 inline-block flex">
                    <p-scrollPanel [style]="{ width: '100%', height: '295px' }">
                      <ul class="list-group">
                        <li
                          *ngFor="let owner of owners"
                          class="list-group-item"
                        >
                          {{ owner }}
                        </li>
                      </ul>
                    </p-scrollPanel>
                  </div>
                </div>
              </div>
              <span
                ><p-progressBar
                  [style]="{
                    height: '6px',
                    width: '192px',
                    'background-color': 'white',
                    'margin-left': '16px'
                  }"
                  mode="indeterminate"
                  *ngIf="loadingOwner"
                ></p-progressBar
              ></span>
            </div>
            -->
            <!-- Ordnerbesitzer (end) -->
          </div>
        </div>
      </div>

      <div
        *ngIf="
          loadingPermissions || loadingFolders || loadingUsersGroups;
          else loading
        "
        class="my-data-container"
      >
        <div style="height: 0px;"></div>
      </div>
      <ng-template #loading>
        <div style="height: 6px;"></div>
      </ng-template>

      <div class="flex justify-between mx-4">
        <div class="">
          <p-button
            icon="pi pi-refresh"
            class="mb-4"
            (click)="loadUserInformations()"
            showDelay="250"
            hideDelay="250"
            pTooltip="Ordnerliste erneut laden"
            type="button"
          ></p-button>
        </div>
        <div class="">
          <p-button
            icon="pi pi-plus"
            class="mb-4"
            *ngIf="
              selectedFolder != null &&
                (selectedUser != null || selectedGroup != null) &&
                selectedCheckbox != null &&
                !parentInheritRights;
              else disabled
            "
            (click)="addAccessRight()"
          ></p-button>
          <ng-template #disabled>
            <p-button
              icon="pi pi-plus"
              class="mb-4"
              [disabled]="true"
            ></p-button
          ></ng-template>
        </div>
      </div>
    </div>

    <div class="">
      <div class="basket" *ngFor="let req of accessRightsRequests">
        <div>
          <span class="header">
            Ordner:
          </span>
          <span>{{ req.folder }} </span>
          <span style="float: right;">
            <p-button
              icon="pi pi-times"
              class="p-button-sm"
              (onClick)="handleRemoveRow(req)"
            ></p-button>
          </span>
        </div>

        <div>
          <span class="header">
            Benutzer/Gruppe:
          </span>
          <span>
            <ng-container *ngIf="req.userRealname != null">{{
              req.userRealname
            }}</ng-container>
            <ng-container *ngIf="req.user != null">
              ({{ req.user }})</ng-container
            >
            <ng-container *ngIf="req.group != null">{{
              req.group
            }}</ng-container>
          </span>
        </div>

        <div>
          <span class="header">
            Berechtigung:
          </span>
          <span>{{ req.accessRightsDescription }}</span>
        </div>

        <div>
          <span class="header">
            Freigabeberechtigt:
          </span>
          <span>{{ req.folderOwners }} </span>
        </div>
      </div>
    </div>

    <!--
    <div class="">
      <p-table [value]="accessRightsRequests">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 24%;">Ordner</th>
            <th style="width: 24%;">Benutzer/Gruppe</th>
            <th style="width: 24%;">Berechtigung</th>
            <th style="width: 24%;">Freigabeberechtigt</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-accessRightsRequest>
          <tr>
            <td>{{ accessRightsRequest.folder }}</td>
            <td>
              <p *ngIf="accessRightsRequest.userRealname != null">
                {{ accessRightsRequest.userRealname }}
              </p>
              <p *ngIf="accessRightsRequest.user != null">
                ({{ accessRightsRequest.user }})
              </p>
              <p *ngIf="accessRightsRequest.group != null">
                {{ accessRightsRequest.group }}
              </p>
            </td>
            <td>
              <p>{{ accessRightsRequest.accessRightsDescription }}</p>
              <p>{{ accessRightsRequest.accessRights }}</p>
            </td>
            <td>
              <ng-template
                ngFor
                let-owner
                [ngForOf]="accessRightsRequest.folderOwners"
              >
                <p>{{ owner }}</p>
              </ng-template>
            </td>
            <td>
              <p-button
                icon="pi pi-times"
                class="mt-2 mb-4"
                (onClick)="handleRemoveRow(accessRightsRequest)"
              ></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    -->

    <div class="flex justify-end mt-3">
      <div class="">
        <p-button
          label="Berechtigungen anfordern"
          class="mt-2"
          *ngIf="accessRightsRequests?.length > 0; else disabled2"
          (click)="requestAccessRights()"
        ></p-button>
        <ng-template #disabled2>
          <p-button
            label="Berechtigungen anfordern"
            class="mt-2"
            [disabled]="true"
          ></p-button
        ></ng-template>
        <p-toast></p-toast>
      </div>
    </div>
  </div>
</div>
