import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserInfo, UserInformation, UserInformationService,} from './user-information.service';
import {Subscription} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {environment} from '@selfservice-portal-frontend/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Home';
  userEmail = '';
  businessUnit: string;

  userInfo: UserInfo;
  admin: boolean;
  keyUser: boolean;
  userInformation: UserInformation;
  loggedIn: boolean;

  baseUrl: string;

  toggle = true;

  loading: boolean = true;
  private userNameSubscription: Subscription;

  constructor(
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private userService: UserInformationService
  ) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  toggleNav() {
    if (window.screen.width > 850 && window.screen.width < 1280) {
      if (this.toggle == true) {
        document.getElementById('myNav').style.width = '33.33334%';
        document.getElementById('main').style.marginLeft = '33.33334%';
        this.toggle = false;
      } else {
        document.getElementById('myNav').style.width = '0';
        document.getElementById('main').style.marginLeft = '0';
        this.toggle = true;
      }
    } else {
      if (this.toggle == true) {
        document.getElementById('myNav').style.width = '100%';
        document.getElementById('main').style.display = 'hidden';
        this.toggle = false;
      } else {
        document.getElementById('myNav').style.width = '0';
        document.getElementById('main').style.display = 'block';
        this.toggle = true;
      }
    }
  }

  closeNav() {
    if (window.screen.width > 850 && window.screen.width < 1280) {
      document.getElementById('myNav').style.width = '0';
      document.getElementById('main').style.marginLeft = '0';
      this.toggle = true;
      (document.getElementById('checkbox') as HTMLInputElement).checked = false;
    } else {
      document.getElementById('myNav').style.width = '0';
      document.getElementById('main').style.display = 'block';
      this.toggle = true;
      (document.getElementById('checkbox') as HTMLInputElement).checked = false;
    }
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.userNameSubscription = this.userService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.userEmail = userInformation.preferredName;
          this.businessUnit = userInformation.businessUnit;
          this.userInfo = userInformation.userInfo;
        },
        complete: () => {
          this.userNameSubscription.unsubscribe();
        },
      }
    );

    this.baseUrl =
      'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=';

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.userNameSubscription.unsubscribe();
  }
  get showContactForm(): boolean {
    return environment.showContactForm;
  }

  onImageMeError($event: ErrorEvent) {
    $event.target["src"] = './assets/Piktogramme/Avatar.svg'
  }
}
