<div class="bg-white min-h-full p-4">
  <div class="grid grid-cols-5 gap-6">
    <div class="col-span-4">
      <p-breadcrumb [home]="home" [model]="items"></p-breadcrumb>
    </div>
    <!--<div class="flex flex-col bg-gray-200 w-1/3 self-center">
    <div class="px-4 py-2 m-2 text-center">Auto reload in:</div>
    <div *ngIf="time > 9; else mitNull" class="clock text-center px-4 py-2 m-2">
      00:{{ time }}
    </div>
    <ng-template #mitNull>
      <div class="clock text-center px-4 py-2 m-2">00:0{{ time }}</div>
    </ng-template>
  </div>-->
    <div class="col-span-1 ui-corner-all clockBorderBox">
      <div class="flex flex-col justify-center">
        <div class="text-center text-lg px-4 pb-2">
          Auto Refresh in:
        </div>
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                class="base-timer__path-remaining"
                d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
              ></path>
            </g>
          </svg>
          <span class="base-timer__label">
            <div *ngIf="time > 9; else mitNull">00:{{ time }}</div>
            <ng-template #mitNull>
              <div>00:0{{ time }}</div>
            </ng-template></span
          >
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="divborder">
    <!-- <div class="inline-block hoverbox_klein shadow-xs mr-16">
      <i class="pi pi-ticket hoverboxpi"></i><br />
      Meine Anfragen
    </div> -->
  </div>
  <p-table
    [columns]="cols"
    [value]="meineAnfragen"
    dataKey="jiraTicketId"
    sortField="initialRequestDate"
    sortMode="single"
    [sortOrder]="-1"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3em;"></th>
        <th
          *ngFor="let col of columns"
          class="xl:{{ col.header }}"
          [pSortableColumn]="col.field"
        >
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-expanded="expanded"
      let-columns="columns"
    >
      <tr>
        <td>
          <a href="#" [pRowToggler]="rowData">
            <i
              [ngClass]="
                expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
              "
            ></i>
          </a>
        </td>
        <td *ngFor="let col of columns">
          <!-- Status (Steps Module) -->
          <ng-template
            [ngIf]="col.field === 'requestStatus'"
            [ngIfElse]="typeOrOtherFields"
          >
            <app-anfragen-progress-steps
              [currentStep]="rowData[col.field]"
            ></app-anfragen-progress-steps>
          </ng-template>

          <!-- Type -->
          <ng-template #typeOrOtherFields>
            <ng-template
              [ngIf]="col.field === 'requestType'"
              [ngIfElse]="otherFields"
            >
              {{ typeDescription(rowData[col.field]) }}
            </ng-template>

            <ng-template #otherFields>
              {{ rowData[col.field] }}
            </ng-template>
          </ng-template>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div
            class="ui-g ui-fluid flex justify-between items-center"
            style="font-size: 16px; padding: 20px;"
          >
            <div class="ui-g-12 ui-md-9" [@rowExpansionTrigger]="'active'">
              <div class="ui-g">
                <div class="ui-g-12">
                  <b>Typ:</b> {{ typeDescription(rowData.requestType) }}
                </div>
                <div class="ui-g-12">
                  <b>Status:</b> {{ statusDescription(rowData.requestStatus) }}
                </div>
                <div class="ui-g-12">
                  <b>Beantragt von:</b> {{ rowData.requester }}
                </div>
                <div class="ui-g-12">
                  <b>Business Unit:</b> {{ rowData.businessUnit }}
                </div>
                <div class="ui-g-12">
                  <b>Letzte Änderung:</b> {{ rowData.lastUpdate }}
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="m-2">Link zu Jira:</div>
              <div class="m-2">
                <a
                  [href]="generateJiraUrl(rowData.jiraTicketId)"
                  target="_blank"
                >
                  <p-button icon="pi pi-external-link"></p-button
                ></a>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
