import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface FolderAccessRightsRequest {
  oid?: string;
  requester: string;
  accessRightRequests: FolderAccessRightRequest[];
}

export interface FolderAccessRightRequest {
  user: string;
  group: string;
  folder: string;
  accessRights: string;
  parentInheritRights: boolean;
}

export interface FolderAccessRightsResponse {
  user: string;
  group: string;
  folder: string;
  accessRights: string;
  businessUnit: string;
}

export interface FolderAccess {
  user: string;
  group: string;
  folder: string;
  accessRights: string;
  businessUnit: string;
  parentInheritRights: boolean;
}

export interface OrdnerAnlegenRequest {
  businessUnit: string;
  requester: string;
  folderParent: string;
  folderName: string;
  folderOwner: string;
  folderSecondaryOwners: string[];
}

export interface OrdnerAnlegenResponse {
  businessUnit: string;
  requester: string;
  folderParent: string;
  folderName: string;
  folderOwner: string;
  folderSecondaryOwners: string[];
}

export interface OrdnerBesitzerRequest {
  businessUnit: string;
  requester: string;
  folderPath: string;
  folderId: string;
  folderOwner: string;
  folderSecondaryOwners: string[];
}

export interface OrdnerBesitzerResponse {
  businessUnit: string;
  requester: string;
  folderPath: string;
  folderId: string;
  folderOwner: string;
  folderSecondaryOwners: string[];
}

export interface BesitzerResponse {
  businessUnit: string;
  folderId: string;
  folderPath: string;
  folderOwner?: string;
  folderSecondaryOwners?: string[];
  recursive?: boolean;
}

@Injectable({ providedIn: 'root' })
export class OrdnerService {
  constructor(private httpClient: HttpClient) {}

  requestFolderAccessRights(
    requestAccessRightRequest: FolderAccessRightsRequest
  ): Observable<FolderAccessRightsResponse[]> {
    return this.httpClient
      .post<any>(
        environment.apiUrlPrefix + '/api/requestaccessrights',
        requestAccessRightRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  createFolder(
    ordnerAnlegenRequest: OrdnerAnlegenRequest
  ): Observable<OrdnerAnlegenResponse> {
    return this.httpClient
      .post<OrdnerAnlegenResponse>(
        environment.apiUrlPrefix + '/api/folder/add',
        ordnerAnlegenRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  changeOwner(
    ordnerBesitzerRequest: OrdnerBesitzerRequest
  ): Observable<OrdnerBesitzerResponse> {
    return this.httpClient
      .post<OrdnerBesitzerResponse>(
        environment.apiUrlPrefix + '/api/folder/owner/modify',
        ordnerBesitzerRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  currentOwner(
    folderId: string,
    recursive: boolean
  ): Observable<BesitzerResponse> {
    return this.httpClient
      .post<BesitzerResponse>(environment.apiUrlPrefix + '/api/folder/owner', {
        folderId: folderId,
        recursive: recursive,
      })
      .pipe(retry(3), timeout(60000));
  }

  folderOwners(): Observable<BesitzerResponse[]> {
    return this.httpClient
      .get<BesitzerResponse[]>(environment.apiUrlPrefix + '/api/folder/owners')
      .pipe(retry(3), timeout(60000));
  }
}
