import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface JiraUser {
  label: string;
  value: {
    emailAddress: string;
    id: string;
    realname: string;
  };
}

@Injectable({ providedIn: 'root' })
export class JiraUserService {
  constructor(private http: HttpClient) {}

  getJiraUser(): Observable<JiraUser[]> {
    return this.http
      .get<JiraUser[]>(environment.jiraApiUrlPrefix + '/jira-api/users')
      .pipe(retry(3), timeout(60000));
  }
}
