<div class="bg-white">
  <!-- <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br /> -->
  <form
    [formGroup]="createUsersForm"
    (ngSubmit)="onSubmit(createUsersForm.value)"
    class="Formular"
    name="Useranlegen"
  >
    <div class="grid grid-cols-3 topgrid">
      <div class="hoverbox_klein shadow-xs">
        <i class="pi pi-user-plus hoverboxpi"></i><br />
        Benutzer anlegen
      </div>

      <div class="mb-10 text-lg userArt col-span-2 relative flex">
        <div class="self-center m-5 w-full mt-0">
          <div class="seperator">
            <div class="seperatortext">Art von Benutzer: <br /></div>
          </div>
          <table>
            <tr>
              <td>
                <p-radioButton
                  name="techUser"
                  [value]="false"
                  label="Persönlicher Benutzer"
                  [(ngModel)]="selectedTechUser"
                  [ngModelOptions]="{ standalone: true }"
                  (click)="updateFormControl()"
                ></p-radioButton>
              </td>
              <td rowspan="2">
                <i
                  class="pi pi-info-circle"
                  showDelay="250"
                  hideDelay="250"
                  pTooltip="Bei technischen Benutzern läuft das Passwort nicht ab."
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <p-radioButton
                  name="techUser"
                  [value]="true"
                  label="Technischer Benutzer"
                  [(ngModel)]="selectedTechUser"
                  [ngModelOptions]="{ standalone: true }"
                  (click)="updateFormControl()"
                ></p-radioButton>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="">
      <div class="seperator">
        <label class="seperatortext">
          Organisatorische Angaben
        </label>
      </div>
      <div class="mb-5">
        <label class="Label" for="businessUnit">
          Business Unit *:
        </label>

        <input
          pInputText
          class="Textfeld"
          id="businessUnit"
          type="text"
          placeholder=""
          formControlName="businessUnit"
          pTooltip="Business Unit"
          tooltipEvent="focus"
          readonly="true"
        />
      </div>
      <div class="mb-5">
        <label class="Label" for="requester">
          Anforderer *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="requester"
          type="text"
          placeholder=""
          formControlName="requester"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>
      <div class="mb-5">
        <label
          class="Label"
          for="contactPerson"
          style="vertical-align: bottom;"
        >
          interner Sachbearbeiter:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="contactPerson"
          type="text"
          placeholder=""
          formControlName="contactPerson"
          pTooltip="Interner Sachbearbeiter"
          tooltipEvent="focus"
        />
      </div>
      <div class="seperator">
        <label class="seperatortext">
          Benutzerinformationen
        </label>
      </div>

      <div class="mb-5 relative">
        <label class="Label" for="title">
          Anrede *:
        </label>
        <div class="inline">
          <p-radioButton
            name="genderGroup"
            value="MALE"
            label="Herr"
            [(ngModel)]="selectedGender"
            [ngModelOptions]="{ standalone: true }"
            (click)="updateFormControl()"
          ></p-radioButton>
          <p-radioButton
            name="genderGroup"
            value="FEMALE"
            label="Frau"
            [(ngModel)]="selectedGender"
            [ngModelOptions]="{ standalone: true }"
            (click)="updateFormControl()"
          ></p-radioButton>
          <p-radioButton
            name="genderGroup"
            value="NO_INFORMATION"
            label="keine Angabe"
            [(ngModel)]="selectedGender"
            [ngModelOptions]="{ standalone: true }"
            (click)="updateFormControl()"
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            showDelay="250"
            hideDelay="250"
            pTooltip="Anrede des Kunden"
          ></i>
        </div>
      </div>
      <div class="mb-5">
        <!--*ngIf="f.techUser.value" -->
        <label class="Label" for="language-dropdown">
          Sprache *:
        </label>

        <p-dropdown
          name="language-dropdown"
          id="language-dropdown"
          [options]="lang"
          placeholder=""
          formControlName="language"
          showDelay="1000"
          hideDelay="500"
        ></p-dropdown>
      </div>
      <div class="mb-5">
        <label class="Label" for="lastName">
          Name *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="lastName"
          type="text"
          placeholder=""
          formControlName="lastName"
          pTooltip="Name des Kunden"
          tooltipEvent="focus"
        />
      </div>
      <div class="mb-5">
        <label class="Label" for="firstName">
          Vorname *:
        </label>
        <input
          pInputText
          class="!important Textfeld"
          id="firstName"
          type="text"
          placeholder=""
          formControlName="firstName"
          pTooltip="Vorname des Kunden"
          tooltipEvent="focus"
        />
      </div>
      <div class="mb-5">
        <label class="Label" for="email">
          E-Mail *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="email"
          type="text"
          placeholder=""
          formControlName="email"
          pTooltip="E-Mail Adresse des Kunden"
          tooltipEvent="focus"
        />
      </div>

      <div class="mb-5">
        <label
          class="Label"
          for="company"
          *ngIf="!selectedTechUser; else techUserCompany"
        >
          Firma:
        </label>

        <ng-template #techUserCompany>
          <label class="Label" for="company">
            Firma *:
          </label>
        </ng-template>
        <input
          pInputText
          class="Textfeld"
          id="company"
          type="text"
          placeholder=""
          formControlName="company"
          pTooltip="Firma des Kunden"
          tooltipEvent="focus"
        />
      </div>
      <div class="mb-5">
        <label class="Label" for="phone">
          Telefon:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="phone"
          type="text"
          placeholder=""
          formControlName="phone"
          pTooltip="Telefonnummer des Kunden"
          tooltipEvent="focus"
        />
      </div>
      <div class="p-0 m-0 flex">
        <span class="text-gray-600 font-thin text-sm self-end"
          >* Pflichtfelder</span
        >
        <div class="ml-auto">
          <p-button
            [disabled]="disabled == true || !createUsersForm.valid"
            label="Benutzer anlegen"
            class="mr-1"
            type="submit"
          ></p-button>
          <p-toast></p-toast>
        </div>
      </div>
    </div>
  </form>
</div>
