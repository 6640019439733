import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-moveit',
  templateUrl: './moveit.component.html',
  styleUrls: ['./moveit.component.scss']
})
export class MoveitComponent implements OnInit {

  items: MenuItem[];

  home: MenuItem;

  constructor() { }

  ngOnInit(): void {
    this.home = { icon: 'pi pi-home', routerLink: '/' }
    this.items = [
        { label: 'MOVEit', routerLink: '/moveit' },
      ];
  }
}
