import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-benutzerverwaltung',
  templateUrl: './benutzerverwaltung.component.html',
  styleUrls: ['./benutzerverwaltung.component.scss'],
})
export class BenutzerverwaltungComponent implements OnInit {
  items: MenuItem[];
  home: MenuItem;

  constructor() {}

  ngOnInit(): void {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [
      { label: 'MOVEit', routerLink: '/moveit' },
      { label: 'Benutzerverwaltung', routerLink: '/moveit/benutzer' },
    ];
  }
}
