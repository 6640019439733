import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { PartialObserver, Subscription } from 'rxjs';
import { environment } from '@selfservice-portal-frontend/environments/environment';
import {
  UserInformation,
  UserInformationService,
} from '../user-information.service';
import {
  CheckUserStatusResponse,
  UserEntsperrenService,
  UserUnlockRequest,
  UserUnlockResponse,
} from './service/user-entsperren.service';

@Component({
  selector: 'app-user-entsperren',
  templateUrl: './user-entsperren.component.html',
  styleUrls: ['./user-entsperren.component.scss'],
})
export class UserEntsperrenComponent implements OnInit {
  unlockUserForm: UntypedFormGroup;
  successMessage: Message;
  failureMessage: Message;
  requesterEmail: string;
  requesterBusinessUnit: string;
  disabled: boolean;
  blocked: boolean;
  private userInformationSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private userInformationService: UserInformationService,
    private userEntsperrenService: UserEntsperrenService
  ) {
    this.unlockUserForm = this.formBuilder.group({
      username: new UntypedFormControl('', Validators.required),
    });

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  ngOnInit(): void {
    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;
          this.requesterBusinessUnit = userInformation.businessUnit;
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  onSubmit(unlockUserRequest: UserUnlockRequest) {
    this.disabled = true;
    this.blocked = true;

    const checkUserStatusRequest = {
      username: unlockUserRequest.username,
    };

    const checkUserStatusSubscription = this.userEntsperrenService
      .checkUserStatus(checkUserStatusRequest)
      .subscribe({
        next: (checkUserStatusResponse) => {
          if (checkUserStatusResponse.unlockable) {
            unlockUserRequest.realname = checkUserStatusResponse.realname;
            unlockUserRequest.requester = this.requesterEmail;
            unlockUserRequest.businessUnit = this.requesterBusinessUnit;
            const unlockUserSubscription = this.userEntsperrenService
              .unlockUser(unlockUserRequest)
              .subscribe({
                next: (_) => {
                  this.messageService.add(this.successMessage);
                  this.resetAndPatchForm();
                },
                error: (err: HttpErrorResponse) => {
                  if (!environment.production) {
                    console.log('unlockUser Error');
                  }
                  this.messageService.add(
                    this.failureMessageToast(err.message)
                  );
                  unlockUserSubscription.unsubscribe();
                  this.disabled = false;
                },
                complete: () => {
                  if (!environment.production) {
                    console.log('unlockUser Completed');
                  }
                  unlockUserSubscription.unsubscribe();
                },
              } as PartialObserver<UserUnlockResponse>);
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Entsperren nicht möglich.',
              detail:
                'Der Benutzer ' +
                unlockUserRequest.username +
                ' wurde nicht gefunden oder kann nicht entsperrt werden!',
              life: 10 * 1000,
            });
            this.resetAndPatchForm();
          }
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('checkUserStatus Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          checkUserStatusSubscription.unsubscribe();
          this.disabled = false;
        },
        complete: () => {
          if (!environment.production) {
            console.log('checkUserStatus Completed');
          }
          checkUserStatusSubscription.unsubscribe();
        },
      } as PartialObserver<CheckUserStatusResponse>);
  }

  private resetAndPatchForm() {
    this.unlockUserForm.reset();
    this.disabled = false;
    this.blocked = false;
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }
}
