<div class="bg-white min-h-full p-4">
  <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br />

  <p-tabView (onChange)="tabViewChange($event, tabView)">
    <p-tabPanel header="Ordner anlegen" *ngIf="showAddFolderForm">
      <!-- ngIf für Dev Umgebung -->
      <app-ordner-anlegen></app-ordner-anlegen>
    </p-tabPanel>
    <p-tabPanel header="Ordner berechtigen">
      <app-ordnerberechtigung></app-ordnerberechtigung>
    </p-tabPanel>
    <p-tabPanel header="Besitzer ändern">
      <app-ordnerbesitzer></app-ordnerbesitzer>
    </p-tabPanel>
  </p-tabView>
</div>
