<p-blockUI [blocked]="blocked">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>
<div class="bg-white">
  <!-- <p-breadcrumb [home]="home" [model]="items" class=""></p-breadcrumb><br /> -->
  <form
    [formGroup]="unlockUserForm"
    (ngSubmit)="onSubmit(unlockUserForm.value)"
    class="Formular"
    name="Userentsperren"
  >
    <div class="">
      <div class="mb-5">
        <label class="Label" for="username">
          Benutzername:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="username"
          type="text"
          placeholder=""
          formControlName="username"
          tooltipEvent="focus"
        />
      </div>

      <div class="p-0 m-0 flex">
        <div class="ml-auto">
          <p-button
            [disabled]="!unlockUserForm.valid || disabled"
            label="Benutzer entsperren"
            class="mr-1"
            type="submit"
          ></p-button>
          <p-toast></p-toast>
        </div>
      </div>
    </div>
  </form>
</div>
