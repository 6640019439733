import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface SupportTicket {
  selectedType: string;
  desc: string;
  requester: string;
  summary?: string;
}

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private httpClient: HttpClient) {}

  submitTicket(supportTicket: SupportTicket): Observable<SupportTicket> {
    console.log(supportTicket);
    return this.httpClient
      .post<SupportTicket>(
        environment.apiUrlPrefix + '/api/contact',
        supportTicket
      )
      .pipe(retry(3), timeout(60000));
  }
}
