<p-blockUI [blocked]="blocked">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>

<div class="bg-white">
  <form
    [formGroup]="changeOwnerForm"
    (ngSubmit)="onSubmit(changeOwnerForm.value)"
    class="Formular"
    name="addFolder"
  >
    <div class="">
      <div class="mb-5">
        <label class="Label" for="businessUnit">
          Business Unit *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="businessUnit"
          type="text"
          formControlName="businessUnit"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5">
        <label class="Label" for="requester">
          Anforderer *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="requester"
          type="text"
          placeholder=""
          formControlName="requester"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5 sm:flex">
        <div
          class="flex content-between flex-wrap justify-end"
          style="margin-top: 7px; max-width: 180px;"
        >
          <div>
            <label class="Label" for="folder">
              Gruppen *:
            </label>
          </div>
          <div style="margin-right: 1.25rem;">
            <p-button
              icon="pi pi-refresh"
              class="mt-2 mb-4"
              (click)="listGroups()"
              showDelay="250"
              hideDelay="250"
              pTooltip="Gruppenliste erneut laden"
              type="button"
            ></p-button>
          </div>
        </div>

        <div class="inline-block">
          <!--          <p-tree
            [value]="groupList"
            [filter]="true"
            selectionMode="single"
            scrollHeight="270px"
            [(selection)]="selectedGroup"
            class="tree"
            [style]="{ height: '357.17px', 'min-width': '333px' }"
            [loading]="loadingGroups"
            (onNodeSelect)="selectGroup($event)"
          ></p-tree>-->
          <p-listbox
            [options]="groupList"
            [(ngModel)]="selectedGroup"
            [ngModelOptions]="{ standalone: true }"
            optionLabel="label"
            [filter]="true"
            [listStyle]="{ 'max-height': '250px' }"
            emptyMessage="Bitte warten..."
            (onChange)="selectGroup($event)"
          >
          </p-listbox>
          <!--          <span
            ><p-progressBar
              [style]="{ height: '6px', 'background-color': 'white' }"
              mode="indeterminate"
              *ngIf="loadingGroups"
            ></p-progressBar
          ></span>-->
          <!--
            <p-dropdown
              name="folder-dropdown"
              id="folder-dropdown"
              [options]="folder"
              formControlName="folder"
              showDelay="1000"
              hideDelay="500"
              [filter]="true"
              placeholder=" "
            ></p-dropdown>-->
          <!-- <p-listbox
              [options]="folder"
              [(ngModel)]="selectedFolder"
              [ngModelOptions]="{ standalone: true }"
              filter="true"
              [listStyle]="{ width: '303px', height: '180px' }"
            >
            </p-listbox>-->
        </div>
      </div>
      <div class="mb-5 sm:flex">
        <div class="self-start" style="margin-top: 7px;">
          <div class="Label">
            <label class="" for="groupOwner-dropdown">
              Besitzer *:
            </label>
          </div>
        </div>
        <div class="inline-block">
          <p-dropdown
            name="groupOwner-dropdown"
            id="groupOwner-dropdown"
            [options]="groupOwner"
            [(ngModel)]="selectedGroupOwner"
            [ngModelOptions]="{ standalone: true }"
            showDelay="1000"
            hideDelay="500"
            [filter]="true"
            placeholder=" "
            optionLabel="label"
          ></p-dropdown>
        </div>
        <div class="inline-block self-center ml-2 text-lg">
          <i
            class="pi pi-info-circle"
            showDelay="250"
            hideDelay="250"
            pTooltip="Der Besitzer der Gruppe ist für die Verwaltung der Gruppenmitglieder verantwortlich. Alle Anfragen von Mitarbeitern zu Mitgliedschaften dieser Gruppe müssen vom Besitzer (oder seines Vertreters) über ein Jira-Ticket genehmigt werden."
          ></i>
        </div>
        <!--<p-listbox
              [options]="folderOwner"
              [(ngModel)]="selectedFolderOwner"
              [ngModelOptions]="{ standalone: true }"
              filter="true"
              [listStyle]="{ width: '303px', height: '180px' }"
            >
            </p-listbox>-->
      </div>

      <div class="mb-5 sm:flex">
        <div class="self-start" style="margin-top: 7px;">
          <label class="Label" for="folderSecondaryOwners-pl">
            Vertretung Besitzer:
          </label>
        </div>
        <div class="inline-block">
          <p-pickList
            #pl
            [source]="groupSecondaryOwners"
            [target]="selectedGroupSecondaryOwners"
            name="folderSecondaryOwners-pl"
            id="folderSecondaryOwners-pl"
            sourceHeader="Verfügbare Benutzer"
            targetHeader="Ausgewählte Benutzer"
            filterBy="label"
            [showSourceControls]="false"
            [showTargetControls]="false"
            [sourceStyle]="{ height: '20rem' }"
            [targetStyle]="{ height: '20rem' }"
          >
            <ng-template let-user pTemplate="item">
              <div class="ui-helper-clearfix">
                <div>
                  {{ user.label }}
                </div>
              </div>
            </ng-template>
          </p-pickList>
          <!--<p-multiSelect                                    // zweiter Entwurf
            name="folderSecondaryOwners-dropdown"
            id="folderSecondaryOwners-dropdown"
            [options]="folderSecondaryOwners"
            formControlName="folderSecondaryOwners"
            showDelay="1000"
            hideDelay="500"
            [filter]="true"
            placeholder=" "
            [showToggleAll]="false"
            [selectedItemsLabel]="this.f.selectedFolderSecondaryOwners + '...'"
          ></p-multiSelect>-->
          <!--<p-listbox                                        // erster Entwurf
            [options]="folderSecondaryOwners"
            [(ngModel)]="selectedFolderSecondaryOwners"
            [ngModelOptions]="{ standalone: true }"
            filter="true"
            [listStyle]="{ width: '303px', height: '180px' }"
          >
          </p-listbox>-->
        </div>
      </div>

      <div class="p-0 m-0 flex">
        <span class="text-gray-600 font-thin text-sm self-end"
          >* Pflichtfelder</span
        >
        <div class="ml-auto">
          <p-button
            [disabled]="
              !changeOwnerForm.valid ||
              selectedGroup == null ||
              selectedGroupOwner == null
            "
            label="Besitzeränderung beantragen"
            class="mr-1"
            type="submit"
            (click)="pl.resetFilter()"
          ></p-button>
          <p-toast></p-toast>
        </div>
      </div>
    </div>
  </form>
</div>
