import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {Message, MessageService} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItGroup, MoveItService} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {
  BesitzerResponse,
  GroupAddRequest,
  GroupAddResponse,
  GruppenverwaltungService,
} from './service/gruppenverwaltung.service';
import {JiraUser, JiraUserService} from "../jira.service";

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss'],
  providers: [MoveItService, GruppenverwaltungService],
})
export class GroupAddComponent implements OnInit {
  successMessage: Message;
  failureMessage: Message;

  addGroupForm: UntypedFormGroup;

  groupAvailable: boolean = false;
  groupDouble: boolean = false;

  _loadingUsers: boolean = false;
  _loadingGroups: boolean = false;

  set loadingUsers(value: boolean) {
    this._loadingUsers = value;
    this.loading.emit(this._loadingGroups || this._loadingUsers);
  }
  get loadingUsers(): boolean {
    return this._loadingUsers;
  }
  set loadingGroups(value: boolean) {
    this._loadingGroups = value;
    this.loading.emit(this._loadingGroups || this._loadingUsers);
  }
  get loadingGroups(): boolean {
    return this._loadingGroups;
  }

  @Output() loading = new EventEmitter<boolean>();

  groups: any[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  private moveItGroupObservable: Observable<MoveItGroup[]>;

  private moveItGroupSubscription: Subscription;
  private userInformationSubscription: Subscription;

  groupOwner: JiraUser[];
  groupSecondaryOwners: JiraUser[];

  selectedGroupOwner: JiraUser;
  selectedGroupSecondaryOwners: JiraUser[];

  users: JiraUser[];

  private jiraUserSubscription: Subscription;
  private jiraUserObservable: Observable<JiraUser[]>;

  private aktuellerBesitzerSubscription: Subscription;
  private aktuellerBesitzerObservable: Observable<BesitzerResponse>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private moveItService: MoveItService,
    private gruppenverwaltungService: GruppenverwaltungService,
    private messageService: MessageService,
    private jiraUserService: JiraUserService,
    private userInformationService: UserInformationService
  ) {
    const regEx = /^[-a-zA-Z0-9-(\-_§!$=@,.+#)]+(\s+[-a-zA-Z0-9-(\-_§!$=@,.+#)]+)*$/; // Whitespace am Anfang des Strings nicht erlaubt + einige Sonderzeichen
    this.selectedGroupSecondaryOwners = []
    this.addGroupForm = this.formBuilder.group({
      businessUnit: new UntypedFormControl('', Validators.required),
      requester: new UntypedFormControl('', Validators.required),
      groupName: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(regEx),
      ]),
      groupDescription: new UntypedFormControl(''),
    });
    this.groups = [];

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  ngOnInit() {
    this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  selectGroup(event) {
    this.groupSecondaryOwners = this.users;
    this.selectedGroupSecondaryOwners = [];
    const key = event.value.value.id;
    if (!environment.production) {
      console.log(event);
      console.log(key);
    }
    this.aktuellerBesitzerObservable = this.gruppenverwaltungService.currentOwner(
      key,
      false
    );
    let i = 0;
    this.aktuellerBesitzerSubscription = this.aktuellerBesitzerObservable.subscribe(
      {
        next: (resp) => {
          if (!environment.production) {
            console.log(resp);
          }
          if (resp != null) {
            for (const jiraUser of this.groupOwner) {
              if (jiraUser.value.emailAddress == resp.groupOwner) {
                this.selectedGroupOwner = jiraUser;
                break;
              }
            }

            if (
              resp.groupSecondaryOwners != null &&
              resp.groupSecondaryOwners.length > 0
            ) {
              this.selectedGroupSecondaryOwners = [];
              for (const secOwner of this.groupSecondaryOwners) {
                for (const racf of resp.groupSecondaryOwners) {
                  if (racf == secOwner.value.emailAddress) {
                    this.selectedGroupSecondaryOwners[i] = secOwner;
                    i++;
                    const tmpList = [];

                    for (const groupSecondaryOwners of this.users) {
                      let addUser: boolean = true;
                      for (const selectedUser of this
                        .selectedGroupSecondaryOwners) {
                        if (selectedUser.label == groupSecondaryOwners.label) {
                          addUser = false;
                          break;
                        }
                      }
                      if (addUser) {
                        tmpList.push(groupSecondaryOwners);
                      } else {
                        if (!environment.production) {
                          console.log('filter ' + groupSecondaryOwners.label);
                        }
                      }
                    }

                    this.groupSecondaryOwners = tmpList;
                  }
                }
              }
            } else {
              this.selectedGroupSecondaryOwners = [];
            }
          } else {
            this.selectedGroupOwner = null;
            this.selectedGroupSecondaryOwners = [];
          }
        },
        complete: () => {
          i = 0;
          this.aktuellerBesitzerSubscription.unsubscribe();
        },
      }
    );
  }

  onSubmit(addGroupRequest) {
    const groupSecondaryOwnersList: string[] = [];

    if (this.selectedGroupSecondaryOwners != null) {
      this.selectedGroupSecondaryOwners.forEach((selectedSecondaryOwner) => {
        groupSecondaryOwnersList.push(selectedSecondaryOwner.value.emailAddress);
      });
    }

    const req: GroupAddRequest = {
      businessUnit: this.addGroupForm.get('businessUnit').value,
      requester: this.addGroupForm.get('requester').value,
      group: this.addGroupForm.get('groupName').value,
      description: this.addGroupForm.get('groupDescription').value,
      groupOwner: this.selectedGroupOwner.value.emailAddress,
      groupSecondaryOwners: groupSecondaryOwnersList,
    };

    const requestGroupSubscription = this.gruppenverwaltungService
      .requestGroup(req)
      .subscribe({
        next: (result) => {
          this.messageService.add(this.successMessage);
          this.resetAndPatchForm();
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('requestGroup Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          requestGroupSubscription.unsubscribe();
        },
        complete: () => {
          if (!environment.production) {
            console.log('requestGroup Completed');
          }
          requestGroupSubscription.unsubscribe();
        },
      } as PartialObserver<GroupAddResponse>);
  }

  validateGroupName(event?) {
    let double: boolean = false;
    if (
      this.addGroupForm.value['groupName'].length < 5 ||
      this.addGroupForm.controls['groupName'].status != 'VALID'
    ) {
      this.groupAvailable = false;
    } else {
      let available: boolean = true;
      for (const group of this.groups) {
        if (
          group.label.toLowerCase() ===
          this.addGroupForm.value['groupName'].toLowerCase()
        ) {
          available = false;
          double = true;
          break;
        }
      }
      this.groupAvailable = available;
    }
    this.groupDouble = double;
    //console.log('GroupAvailable' + this.groupAvailable);
    //console.log('GroupDouble' + this.groupDouble);
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  private resetAndPatchForm() {
    this.addGroupForm.reset();
    this.groupAvailable = false;
    this.addGroupForm.patchValue({
      businessUnit: this.requesterBusinessUnit,
      requester: this.requesterEmail,
    });
  }

  loadUserInformations() {
    this.loadingGroups = true;
    this.loadingUsers = true;
    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          if (userInformation.businessUnit != '') {
            this.requesterEmail = userInformation.preferredName;
            this.requesterBusinessUnit = userInformation.businessUnit;
            this.resetAndPatchForm();

            this.jiraUserObservable = this.jiraUserService.getJiraUser();
            this.jiraUserSubscription = this.jiraUserObservable.subscribe({
              next: (users) => {
                this.groupOwner = users;
                this.groupSecondaryOwners = users;
                this.users = users;
              },
              complete: () => {
                this.jiraUserSubscription.unsubscribe();
                this.loadingUsers = false;

                this.moveItGroupObservable = this.moveItService.getGroupList();
                this.moveItGroupSubscription = this.moveItGroupObservable.subscribe(
                  {
                    next: (groups) => {
                      this.groups = groups;
                    },
                    complete: () => {
                      this.moveItGroupSubscription.unsubscribe();
                      this.loadingGroups = false;
                    },
                  }
                );
              },
            });
          }
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }
}
