import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface InfoRow {
  userid: string;
  username: string;
  realname: string;
  email: string;

  groupid: string;
  groupname: string;
  description: string;
}
@Injectable({ providedIn: 'root' })
export class MoveItService {
  constructor(private http: HttpClient) {}

  getUserList(): Observable<InfoRow[]> {
    return this.http
      .get<InfoRow[]>(
        environment.moveitApiUrlPrefix + '/moveit-api/report/runUserGroupReport'
      )
      .pipe(retry(3), timeout(60000));
  }
}
