import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '@selfservice-portal-frontend/environments/environment';

export interface UserUnlockRequest {
  username: string;
  realname: string;
  requester: string;
  businessUnit: string;
}

export interface UserUnlockResponse {
  username: string;
  status: string;
  unlockable: boolean;
}

export interface CheckUserStatusRequest {
  username: string;
}

export interface CheckUserStatusResponse {
  userid: string;
  username: string;
  realname: string;
  status: string;
  unlockable: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserEntsperrenService {
  constructor(private httpClient: HttpClient) {}

  checkUserStatus(
    checkUserStatusRequest: CheckUserStatusRequest
  ): Observable<CheckUserStatusResponse> {
    return this.httpClient
      .post<CheckUserStatusResponse>(
        environment.apiUrlPrefix + '/moveit-api/checkUserStatus',
        checkUserStatusRequest
      )
      .pipe(retry(3), timeout(60000));
  }

  unlockUser(
    userUnlockRequest: UserUnlockRequest
  ): Observable<UserUnlockResponse> {
    return this.httpClient
      .post<UserUnlockResponse>(
        environment.apiUrlPrefix + '/api/user/unlock',
        userUnlockRequest
      )
      .pipe(retry(3), timeout(60000));
  }
}
