import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-anfragen-progress-steps',
  templateUrl: './anfragen-progress-steps.component.html',
  styleUrls: ['./anfragen-progress-steps.component.scss'],
})
export class AnfragenProgressStepsComponent implements OnInit {
  constructor() {}

  items: MenuItem[];

  activeIndex: number;

  activeIndexMap: Record<string, number> = {
    REQUESTED: 0,
    REQUEST_APPROVED: 1,
    REQUEST_DENIED: 1,
    CANNOT_REQUEST: 2,
    MOVEIT_CONFIGURED: 2,
    REQUEST_FULFILLED: 2,
  };

  @Input()
  set currentStep(step: string) {
    this.activeIndex = this.activeIndexMap[step];
    if ('REQUEST_DENIED' == step) {
      this.items = [{ label: 'Warte auf Genehmigung' }, { label: 'Abgelehnt' }];
    } else if ('CANNOT_REQUEST' == step) {
      this.items = [
        { label: 'Warte auf Genehmigung' },
        { label: 'Genehmigt' },
        { label: 'Fehlgeschlagen' },
      ];
    } else {
      this.items = [
        { label: 'Warte auf Genehmigung' },
        { label: 'Genehmigt' },
        { label: 'Erledigt' },
      ];
    }
  }

  get stepDescription() {
    if (this.items[this.activeIndex] != undefined) {
      return this.items[this.activeIndex].label;
    } else {
      return '';
    }
  }

  ngOnInit() {
    // empty
  }
}
