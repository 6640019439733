<div class="bg-white hoehe relative">

  <div
    class="absolute inset-x-0 top-0 text-center text-2xl leading-10 mt-10"
    id="headline"
  >
    {{welcomeMessage}}

  </div>

  <div
    class="absolute inset-x-0 top-10 text-center text-sm leading-10 mt-10"
    id="versioninfo"
  >
    {{versionInfo}}

  </div>

  <!--
    <div (click)="errorChange()" class="absolute inset-x-0 top-0 h-10 w-10"></div>

  <div class="absolute inset-x-0 bottom-0" *ngIf="errorMessage == true">
    <div class="mx-auto h-auto w-1/4 bg-red-500 shadow-lg rounded-lg px-6 py-3">
      <span class="text-white font-medium">
        Sehr geehrte Damen und Herren, wir führen am
        {{ today | date: "EEEE":"de" }} dem
        {{ today | date: "dd.MM.yyyy" }} zwischen
        {{ today | date: "HH:mm" }} und {{ today | date: "HH:mm" }} planmäßige
        {{ grund }} durch.<br />
        Wir bitten um Ihr Verständnis.</span
      >
    </div>
  </div>-->
  <!--
    {{ today | date: "dd.MM.yyyy" }} <br />
    {{ today | date: "HH:mm" }} <br />
    {{ today | date: "EEEE":"de" }} <br />
     -->
</div>
