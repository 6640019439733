import {Component, LOCALE_ID, OnInit} from '@angular/core';
import localeDe from '@angular/common/locales/de';
import {DatePipe, registerLocaleData} from '@angular/common';
import {gitVersion} from "../../environments/git-version";

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-home,date-pipe',
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  today: number = Date.now();

  title = 'BPG Self Service Portal';
  errorMessage: boolean;

  day: string;
  date: string;
  von: string;
  bis: string;
  grund: string;
  welcomeMessage: string;
  versionInfo: string

  constructor() {
  }

  ngOnInit(): void {
    this.grund = 'Wartungsarbeiten';
    this.welcomeMessage = 'Herzlich Willkommen im ' +
      'Self-Service Portal der ' +
      'Bertelsmann Marketing Services!';

    const pipe = new DatePipe('de-DE');
    const str = pipe.transform(new Date(gitVersion.date), 'dd.MM.yyyy HH:mm:ss zzzz')
    this.versionInfo = 'Version: ' + gitVersion.commit + ' vom ' + str;
  }

  errorChange() {
    if (this.errorMessage == false) {
      this.errorMessage = true;
    } else this.errorMessage = false;
  }

}
