<p-table
  #dt
  [columns]="cols"
  [value]="users"
  [paginator]="true"
  [autoLayout]="true"
  [rows]="15"
  sortField="realname"
  sortMode="single"
  [loading]="loadingReport"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
    <tr>
      <th *ngFor="let col of columns" [ngSwitch]="col.field">
        <input
          pInputText
          type="text"
          (input)="dt.filter($event.target.value, col.field, 'contains')"
        />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>
</p-table>
