import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, Message, MessageService, SelectItem, TreeNode,} from 'primeng/api';
import {Observable, PartialObserver, Subscription} from 'rxjs';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {MoveItGroup, MoveItService, MoveItUser,} from '../moveit/service/moveit.service';
import {UserInformation, UserInformationService,} from '../user-information.service';
import {JiraUser, JiraUserService} from '../jira.service';
import {
  BesitzerResponse,
  FolderAccess,
  FolderAccessRightsRequest,
  FolderAccessRightsResponse,
  OrdnerService,
} from './service/ordner.service';

@Component({
  selector: 'app-ordnerberechtigung',
  templateUrl: './ordnerberechtigung.component.html',
  styleUrls: ['./ordnerberechtigung.component.scss'],
  providers: [MoveItService],
})
export class OrdnerberechtigungComponent implements OnInit {
  foldersTree: TreeNode[];

  folderSearchInput: any;
  users: SelectItem[];
  groups: SelectItem[];

  selectedFolder: any;
  selectedUser: any;
  selectedGroup: any;
  selectedCheckbox: string[];

  accessRightsRequests: any[];
  parentInheritRights: boolean = false;
  parentInheritRightsCheckboxEnabled: boolean = false;

  loadingFolders: boolean;
  loadingUsersGroups: boolean;
  loadingPermissions: boolean;
  loadingOwner: boolean;

  successMessage: Message;
  failureMessage: Message;

  jiraUsersMap: any;
  jiraUsers: JiraUser[];

  requesterEmail: string;
  requesterBusinessUnit: string;

  accessRightsMapping: Map<string, string>;
  blocked: boolean = false;

  constructor(
    private moveItService: MoveItService,
    private userInformationService: UserInformationService,
    private ordnerService: OrdnerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private jiraUserService: JiraUserService
  ) {
    this.accessRightsRequests = [];
    this.accessRightsMapping = new Map();
    this.accessRightsMapping.set('R', 'Lesen');
    this.accessRightsMapping.set('W', 'Schreiben');
    this.accessRightsMapping.set('D', 'Löschen');
    this.accessRightsMapping.set('L', 'Liste');
    this.accessRightsMapping.set('N', 'Benachrichtigen');
    this.accessRightsMapping.set('E', 'Unterordner');
    // this.accessRightsMapping.set('A', 'Admin');

    this.successMessage = {
      severity: 'success',
      summary: 'Ihre Anfrage wurde erstellt.',
      detail: '',
      life: 4000,
    };

    this.failureMessage = {
      severity: 'error',
      summary: 'Es ist ein Fehler aufgetreten!',
      detail: 'Bitte versuchen Sie es später erneut.',
      life: 4000,
    };
  }

  private treeNodeObservable: Observable<TreeNode[]>;
  private moveItUserObservable: Observable<MoveItUser[]>;
  private moveItGroupObservable: Observable<MoveItGroup[]>;

  private folderOwnerSubscription: Subscription;
  private folderSubscription: Subscription;
  private moveItUserSubscription: Subscription;
  private moveItGroupSubscription: Subscription;
  private userInformationSubscription: Subscription;
  private folderAccessSubscription: Subscription;

  private jiraUserSubscription: Subscription;
  private jiraUserObservable: Observable<JiraUser[]>;

  ngOnInit() {
    // this.loadUserInformations();
  }

  ngOnDestroy(): void {
    if (this.userInformationSubscription) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  listFolders() {
    this.loadingFolders = true;
    this.treeNodeObservable = this.moveItService.getFolderList();
    this.folderSubscription = this.treeNodeObservable.subscribe({
      next: (folders) => {
        this.foldersTree = folders;
        this.loadingFolders = false;
      },
      complete: () => {
        this.folderSubscription.unsubscribe();
      },
    });
  }

  selectFolder(event) {
    this.listFolderAccess();
    // this.getFolderOwner();
  }

  resetSearch(event) {
    this.folderSearchInput = '';
    this.listFolders();
  }

  selectUser(event) {
    this.selectedGroup = null;
    this.listFolderAccess();
  }

  selectGroup(event) {
    this.selectedUser = null;
    this.listFolderAccess();
  }

  handleRemoveRow(accessRightsRequest) {
    // https://gist.github.com/scottopolis/6e35cf0d53bae81e6161662e6374da04
    const removeIndex = this.accessRightsRequests
      .map(function (item) {
        return item;
      })
      .indexOf(accessRightsRequest);
    this.accessRightsRequests.splice(removeIndex, 1);
  }

  handleTabChange(event) {
    // required?
  }

  changeParentInheritRights(checked) {
    console.log(this.selectedFolder);
    this.blocked = true;
    if (!checked.checked) {
      this.folderAccessSubscription = this.moveItService
        .getFolderAccessInherit({
          user: null,
          group: null,
          folder: this.selectedFolder.data,
        })
        .subscribe({
          next: (result) => {
            this.blocked = false;
            this.confirmationService.confirm({
              message:
                'Die Einstellung "Inherit Access From Parent" für den Order "' +
                this.selectedFolder.label +
                '" kann nachträglich nicht wieder im SSP aktiviert werden.<br>' +
                'Folgende bisher implizit vererbte Berechtigungen werden damit explizit vergeben:<br><br>' +
                this.beautifyAccessRights(result) +
                '<br>Der Änderungsprozess via Jira für diese Einstellung muss erfolgreich durchgeführt werden,' +
                '<br>bevor explizite Berechtigungen vergeben werden können.' +
                '<br>Möchten Sie diese Einstellung ändern?',
              accept: () => {
                this.selectedCheckbox = [];
                const accessRightsRequest = {
                  folder: this.selectedFolder.data,
                  folderId: this.selectedFolder.key,
                  user: '',
                  group: '',
                  accessRights: '',
                  accessRightsDescription: '',
                  accessRightsInherit: JSON.stringify(result),
                  parentInheritRights: this.parentInheritRights,
                };
                this.parentInheritRightsCheckboxEnabled = false;

                if (!environment.production) {
                  console.log(accessRightsRequest);
                }

                this.accessRightsRequests.push(accessRightsRequest);
                this.requestAccessRights();
              },
              reject: () => {
                this.parentInheritRights = true;
                this.parentInheritRightsCheckboxEnabled = true;
              },
            });
          },
          complete: () => {
            this.folderAccessSubscription.unsubscribe();
          },
        });
    }
  }

  private beautifyAccessRights(folderAccess: FolderAccess[]): any {
    let result = '';
    for (const fa of folderAccess) {
      if (fa.user != null) {
        result = result + '<b>' + fa.user + '</b>';
      }
      if (fa.group != null) {
        result = result + '<b>' + fa.group + '</b>';
      }
      result = result + ': ';
      for (let i = 0; i < fa.accessRights.length; i++) {
        result =
          result +
          this.accessRightsMapping.get(fa.accessRights.charAt(i)) +
          ' ';
      }
      result = result + '<br>';
    }
    return result;
  }

  requestAccessRights() {
    this.blocked = true;
    const folderAccessRightsRequest: FolderAccessRightsRequest = {
      requester: this.userInformationService.preferredName,
      accessRightRequests: this.accessRightsRequests,
    };

    const requestAccessRightsSubscription = this.ordnerService
      .requestFolderAccessRights(folderAccessRightsRequest)
      .subscribe({
        next: (result) => {
          this.messageService.add(this.successMessage);
          this.accessRightsRequests = [];
        },
        error: (err: HttpErrorResponse) => {
          if (!environment.production) {
            console.log('requestAccessRights Error');
          }
          this.messageService.add(this.failureMessageToast(err.message));
          requestAccessRightsSubscription.unsubscribe();
          this.blocked = false;
        },
        complete: () => {
          if (!environment.production) {
            console.log('requestAccessRights Completed');
          }
          requestAccessRightsSubscription.unsubscribe();
          this.blocked = false;
        },
      } as PartialObserver<FolderAccessRightsResponse[]>);
  }

  addAccessRight() {
    if (this.selectedFolder != null) {
      this.loadingOwner = true;
      this.blocked = true;
      const folderOwners: string[] = [];

      this.folderOwnerSubscription = this.ordnerService
        .currentOwner(this.selectedFolder.key, true)
        .subscribe({
          next: (owner: BesitzerResponse) => {
            if (owner != null) {
              folderOwners.push(this.lookupJiraUserForOwner(owner.folderOwner));
              for (const o of owner.folderSecondaryOwners) {
                folderOwners.push(this.lookupJiraUserForOwner(o));
              }
            } else {
              folderOwners.push('Keyuser');
            }
          },
          complete: () => {
            this.folderOwnerSubscription.unsubscribe();
            this.loadingOwner = false;
            this.addAccessRightToBasket(folderOwners);
          },
        });
    }
  }

  private addAccessRightToBasket(folderOwners: string[]) {
    // A = Admin, E = Unterordner, D = Löschen, L = Liste, N = Benachrichtigen, R = Lesen, W = Schreiben
    if (!environment.production) {
      if (this.selectedFolder != null) {
        console.log('folder=' + this.selectedFolder.data);
      }
      if (this.selectedUser != null) {
        console.log('user=' + this.selectedUser.username);
        console.log('user=' + this.selectedUser.realname);
      }
      if (this.selectedGroup != null) {
        console.log('group=' + this.selectedGroup.name);
      }
    }

    let accessRights = '';
    const accessRightsDescription = [];

    this.selectedCheckbox.forEach((c, i) => {
      if (!environment.production) {
        console.log(this.accessRightsMapping.get(c));
      }
      accessRights = accessRights + c;
      accessRightsDescription.push(this.accessRightsMapping.get(c));
    });

    console.log(this.selectedUser)
    const tmpUser: string =
      this.selectedUser != null ? this.selectedUser.username : null;
    const tmpUserRealname: string =
      this.selectedUser != null ? this.selectedUser.realname : null;
    const tmpGroup: string =
      this.selectedGroup != null ? this.selectedGroup.name : null;

    if (tmpUser != null || tmpGroup != null) {
      const accessRightsRequest = {
        folder: this.selectedFolder.data,
        folderId: this.selectedFolder.key,
        user: tmpUser,
        userRealname: tmpUserRealname,
        group: tmpGroup,
        accessRights: accessRights,
        accessRightsDescription: accessRightsDescription,
        parentInheritRights: null,
        accessRightsInherit: null,
        folderOwners: folderOwners,
      };

      if (!environment.production) {
        console.log(accessRightsRequest);
      }
      this.accessRightsRequests.push(accessRightsRequest);
    }
    this.blocked = false;
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  private lookupJiraUserForOwner(folderOwner: string): string {
    const jiraUser = this.jiraUsersMap.get(folderOwner);
    if (jiraUser != null) {
      return jiraUser.label;
    }
    return folderOwner;
  }

  private listFolderAccess() {
    if (this.selectedFolder != null) {
      this.loadingPermissions = true;
      this.selectedCheckbox = [];
      this.folderAccessSubscription = this.moveItService
        .getFolderAccess({
          user: this.selectedUser != null ? this.selectedUser.username : null,
          group: this.selectedGroup != null ? this.selectedGroup.name : null,
          folder: this.selectedFolder.data,
        })
        .subscribe({
          next: (result) => this.mapFolderAccess(result),
          complete: () => {
            this.folderAccessSubscription.unsubscribe();
            this.loadingPermissions = false;
          },
        });
    }
  }

  private mapFolderAccess(folderAccess: FolderAccess) {
    this.selectedCheckbox = [];
    this.parentInheritRights = folderAccess.parentInheritRights;
    this.parentInheritRightsCheckboxEnabled = folderAccess.parentInheritRights;

    if (folderAccess.accessRights != null) {
      const chars = [...folderAccess.accessRights];
      chars.forEach((c, i) => this.selectedCheckbox.push(c));
    }
  }

  private failureMessageToast(text: string): Message {
    return {
      ...this.failureMessage,
      detail: `${text}`,
    };
  }

  loadUserInformations() {
    this.loadingFolders = true;
    this.loadingUsersGroups = true;

    this.userInformationSubscription = this.userInformationService.userInformationObservable.subscribe(
      {
        next: (userInformation: UserInformation) => {
          this.requesterEmail = userInformation.preferredName;

          this.moveItUserObservable = this.moveItService.getUserList();
          this.moveItUserSubscription = this.moveItUserObservable.subscribe({
            next: (users) => {
              this.users = users;
            },
            complete: () => {
              this.moveItUserSubscription.unsubscribe();
              this.loadingUsersGroups = false;

              this.moveItGroupObservable = this.moveItService.getGroupList();
              this.moveItGroupSubscription = this.moveItGroupObservable.subscribe({
                next: (groups) => {
                  this.groups = groups;
                },
                complete: () => {
                  this.moveItGroupSubscription.unsubscribe();
                  this.loadingUsersGroups = false;

                  this.jiraUserObservable = this.jiraUserService.getJiraUser();
                  this.jiraUserSubscription = this.jiraUserObservable.subscribe({
                    next: (users) => {
                      this.jiraUsers = users;
                      this.jiraUsersMap = new Map<string, JiraUser>();
                      for (const user of users) {
                        this.jiraUsersMap.set(user.value.emailAddress, user);
                      }
                    },
                    complete: () => {
                      this.jiraUserSubscription.unsubscribe();
                      this.listFolders();
                    },
                  });
                },
              });
            },
          });
        },
        complete: () => {
          this.userInformationSubscription.unsubscribe();
        },
      }
    );
  }

  get accessrightCheckboxDisabled(): boolean {
    if (
      this.loadingPermissions ||
      this.loadingFolders ||
      this.loadingUsersGroups ||
      this.parentInheritRights
    ) {
      return true;
    }

    if (this.selectedUser == null && this.selectedGroup == null) {
      return true;
    }

    return false;
  }
}
