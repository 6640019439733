<p-dialog modal="true" [(visible)]="displayInfoDialog">
  <p-header>
    Permission
  </p-header>
  <div>
    <ul>
      <li>
        (R) Lesen: Der Benutzer bzw. die Gruppe kann Dateien aus diesem Ordner
        öffnen/lesen.
      </li>
      <li>
        (W) Schreiben: Der Benutzer bzw. die Gruppe kann Dateien in diesem
        Ordner hochladen/sie überschreiben.
      </li>
      <li>
        (D) Löschen: Der Benutzer bzw. die Gruppe kann Dateien aus diesem Ordner
        löschen.
      </li>
      <li>
        (L) Liste: Der Benutzer bzw. die Gruppe kann alle Dateien, die zu diesem
        Ordner hinzugefügt werden, sehen.
      </li>
      <li>
        (N) Benachrichtigen: E-Mail Benachrichtigungen werden an den Benutzer
        bzw. die Gruppe versendet, wenn neue Dateien in diesen Ordner
        hochgeladen wurden (Ausnahme: Dateien in eigenem Ordner).
      </li>
      <li>
        (E) Unterordner: Der Benutzer bzw. die Gruppe kann Unterordner in diesem
        Ordner hinzufügen und umbenennen und entfernen.
      </li>
      <li>
        (A) Admin: Hier fehlt noch Text.
      </li>
      <li>
        (S) unbekannt: Hier fehlt noch Text.
      </li>
    </ul>
  </div>
</p-dialog>

<p-table
  #dt
  [value]="infoRows"
  [paginator]="true"
  [loading]="loadingReport"
  [autoLayout]="true"
  [rows]="15"
  [columns]="selectedColumns"
>
  <ng-template pTemplate="caption">
    <div class="ui-helper-clearfix" style="text-align: left;">
      <p-multiSelect
        [options]="cols"
        [(ngModel)]="selectedColumns"
        optionLabel="header"
        selectedItemsLabel="{0} Spalten ausgewählt"
        [style]="{ minWidth: '200px' }"
        defaultLabel="Spalten auswählen"
      ></p-multiSelect>

      <button
        type="button"
        pButton
        (click)="showInfoDialog()"
        style="margin-right: 0.5em; float: right;"
        icon="pi pi-info-circle"
        label="Info"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-file-o"
        iconPos="left"
        label="CSV"
        (click)="dt.exportCSV()"
        style="margin-right: 0.5em; float: right; background-color: #34a835;"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-file-excel"
        iconPos="left"
        label="EXCEL"
        (click)="exportExcel()"
        style="margin-right: 0.5em; float: right;"
        class="ui-button-success"
      ></button>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
    </tr>
    <tr>
      <th *ngFor="let col of columns" [ngSwitch]="col.field">
        <input
          pInputText
          type="text"
          (input)="dt.filter($event.target.value, col.field, 'contains')"
        />
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>

  <!--
    <ng-template pTemplate="body" let-infoRow let-columns="columns">
      <tr>
        <td *ngFor="let col of cols">
          {{ infoRow[col.field] }}
        </td>
      </tr>
    </ng-template>
    -->
</p-table>
