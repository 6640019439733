<p-blockUI [blocked]="loadingGroups || loadingUserList || loadingUsers">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
</p-blockUI>

<div>
  <form
    [formGroup]="modifyGroupForm"
    (ngSubmit)="onSubmit(modifyGroupForm.value)"
    class="Formular"
    name="modifyGroupForm"
    style="max-width: '60rem';"
  >
    <div class="">
      <div class="mb-5">
        <label class="Label" for="businessUnit">
          Business Unit *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="businessUnit"
          type="text"
          placeholder=""
          formControlName="businessUnit"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-5">
        <label class="Label" for="requester">
          Anforderer *:
        </label>
        <input
          pInputText
          class="Textfeld"
          id="requester"
          type="text"
          placeholder=""
          formControlName="requester"
          pTooltip=""
          tooltipEvent="focus"
          readonly="true"
        />
      </div>

      <div class="mb-6">
        <label class="Label" for="groupName-dropdown">
          Gruppenname *:
        </label>
        <p-dropdown
          name="groupName-dropdown"
          id="groupName-dropdown"
          [options]="groups"
          formControlName="groupName"
          showDelay="1000"
          hideDelay="500"
          [filter]="true"
          (onChange)="selectGroup($event)"
        ></p-dropdown>
      </div>

      <div class="mb-5">
        <label
          class="Label"
          style="vertical-align: top;"
          for="groupDescription"
        >
          Beschreibung:
        </label>
        <textarea
          pInputText
          class="Textfeld min-h max-h"
          id="groupDescription"
          type="text"
          placeholder=""
          formControlName="groupDescription"
          pTooltip=""
          tooltipEvent="focus"
        ></textarea>
      </div>
      <div class="mb-2">
        <span class="font-bold text-schwarz">
          Benutzer zu Gruppe hinzufügen/aus Gruppe entfernen:</span
        >
      </div>
      <div>
        <p-pickList
          #pl
          [source]="usersAvailable"
          [target]="usersSelected"
          sourceHeader="Verfügbare Benutzer"
          targetHeader="Gruppenmitglieder"
          filterBy="label"
          [showSourceControls]="false"
          [showTargetControls]="false"
          [sourceStyle]="{ height: '20rem' }"
          [targetStyle]="{ height: '20rem' }"
          (onMoveToTarget)="sortLists($event)"
          (onMoveToSource)="sortLists($event)"
          (onMoveAllToTarget)="sortLists($event)"
          (onMoveAllToSource)="sortLists($event)"
        >
          <ng-template let-user pTemplate="item">
            <div class="ui-helper-clearfix">
              <div>
                {{ user.label }}
              </div>
            </div>
          </ng-template>
        </p-pickList>

        <span
          ><p-progressBar
            [style]="{
              height: '6px',
              'background-color': 'white',
              width: '400px'
            }"
            mode="indeterminate"
            *ngIf="loadingUsers"
          ></p-progressBar
        ></span>
      </div>

      <div *ngIf="loadingUsers; else loading" class="my-data-container">
        <div style="height: 0px;"></div>
      </div>
      <ng-template #loading>
        <div style="height: 6px;"></div>
      </ng-template>

      <div class="pl-5 pr-5 mt-4 flex">
        <span class="text-gray-600 font-thin text-sm self-end"
          >* Pflichtfelder</span
        >
        <div class="ml-auto">
          <p-button
            icon="pi pi-refresh"
            class="mt-2 mb-4 mx-2"
            (click)="loadUserInformations()"
            showDelay="250"
            hideDelay="250"
            pTooltip="Benutzerliste erneut laden"
            tooltipPosition="left"
            type="button"
            *ngIf="!loadingUserList; else refreshButton"
          ></p-button>
          <ng-template #refreshButton>
            <p-button
              icon="pi pi-refresh pi-spin"
              class="mt-2 mb-4 mx-2"
              (click)="loadUserInformations()"
              type="button"
            ></p-button>
          </ng-template>
          <p-button
            [disabled]="!modifyGroupForm.valid || usersSelected.length == 0"
            label="Änderung durchführen"
            class="mr-1"
            type="submit"
            (click)="pl.resetFilter()"
          ></p-button>

          <p-toast></p-toast>
        </div>
      </div>
    </div>
  </form>
</div>
