<div class="h-screen" [class.blurred]="loading">
  <!--Topnav-->
  <header class="fixed top-0 w-full bg-white shadow text-center h-20 topnav">
    <ul class="flex items-center justify-between list-none h-20">
      <!--Topnav links-->
      <div
        class="flex items-center flex-shrink-0 w-1/4 xl:w-1/6 justify-center"
      >
        <li class="block xl:hidden">
          <div id="menuToggle">
            <input type="checkbox" (click)="toggleNav()" id="checkbox" />
            <span></span><span></span><span></span>
          </div>
        </li>
        <li class="hidden xl:block float-left">
          <a
            routerLink="/"
            class="bg-white shadow-none"
            (click)="setTitle('Self-Service Portal')"
          >
            <img
              src="./assets/bms_logo_header.jpg"
              alt="Bertelsmann Marketing Services Logo"
              class="mx-auto h-20 w-auto"
            />
          </a>
        </li>
      </div>
      <li class="hidden md:block xl:hidden float-left">
        <a
          routerLink="/"
          class="bg-white shadow-none"
          (click)="setTitle('Self-Service Portal')"
        >
          <img
            src="./assets/bms_logo_header.jpg"
            alt="Bertelsmann Marketing Services Logo"
            class="mx-auto h-20 w-auto"
          />
        </a>
      </li>
      <!--Topnav rechts -->
      <div class="flex text-center">
        <div class="self-center">
          <li class="float-right">
            <span class="normal-case" *ngIf="userEmail !== ''"
              >Angemeldet als: {{ this.userEmail }} -
              {{ this.businessUnit }}
            </span>
          </li>
        </div>

        <li *ngIf="showContactForm" class="float-right hidden sm:block">
          <object>
            <a
              class="avatar cursor-pointer avatarhover"
              routerLink="/kontakt"
              (click)="setTitle('Self-Service Portal | Kontakt')"
            >
              <i
                class="pi pi-envelope"
                style="font-size: 2.5rem;"
                pTooltip="Kontakt"
              ></i>
              <!-- <img
                src="./assets/Piktogramme/Question.svg"
                alt="Question"
                class="rounded-full"
                pTooltip="Kontakt"
              /> -->
            </a>
          </object>
        </li>
        <li class="float-right hidden sm:block">
          <object>
            <a
              class="avatar cursor-pointer avatarhover"
              href="https://bmshub.atlassian.net/wiki/spaces/SSPH/overview"
              target="_blank"
            >
              <i
                class="pi pi-question-circle"
                style="font-size: 2.5rem;"
                pTooltip="Hilfe"
              ></i>

              <!-- <img
                src="./assets/Piktogramme/Question.svg"
                alt="Question"
                class="rounded-full"
                pTooltip="Hilfe"
              /> -->
            </a>
          </object>
        </li>
        <li class="float-right hidden sm:block">
          <object>
            <a class="cursor-pointer">
              <!--
              <img
                src="./assets/Piktogramme/Avatar.svg"
                class="rounded-full avatarhover"
                style="max-width: 40px;"
              />-->
              <img
                (error)="onImageMeError($event)"
                id="profilePicture"
                src="https://graph.microsoft.com/v1.0/me/photo/$value"
                class="rounded-full avatarhover"
                style="max-width: 40px;"
              />
            </a>
          </object>
        </li>
      </div>
    </ul>
  </header>

  <!--Sidenav-->

  <div
    class="fixed bg-blue-500 mr-2 text-white h-full min-h-screen w-1/6 top-auto z-50 hidden xl:block"
  >
    <div class="Kategorie">
      <a
        class="Punkt hover:bg-blue-800"
        routerLink="/"
        (click)="setTitle('Self-Service Portal')"
        ><i class="pi pi-home"></i> home</a
      >
      <br />
      <!--
      <a
        class="Unterpunkt hover:bg-blue-800"
        routerLink="/info"
        (click)="setTitle('Self-Service Portal | Info')"
      >
        <i class="pi pi-info-circle"></i> Info</a
      >-->
    </div>

    <div class="Kategorie">
      <a
        class="Punkt hover:bg-blue-800"
        routerLink="/anfragen"
        (click)="setTitle('Self-Service Portal | Meine Anfragen')"
      >
        <i class="pi pi-ticket"></i> meine anfragen</a
      >
    </div>

    <div class="Kategorie">
      <a
        class="Punkt hover:bg-blue-800"
        routerLink="/moveit"
        (click)="setTitle('Self-Service Portal | MOVEit')"
        >moveit</a
      >
      <a
        class="Unterpunkt hover:bg-blue-800 active:bg-blue-800"
        routerLink="/moveit/benutzer"
        (click)="setTitle('Self-Service Portal | MOVEit')"
      >
        <i class="pi pi-user"></i> benutzerverwaltung</a
      >
      <a
        class="Unterpunkt hover:bg-blue-800 active:bg-blue-800"
        routerLink="/moveit/gruppen"
        (click)="setTitle('Self-Service Portal | MOVEit')"
        ><i class="pi pi-users"></i> gruppenverwaltung</a
      >

      <!--
      <a
        class="Unterpunkt hover:bg-blue-800 active:bg-blue-800"
        routerLink="/moveit/anlegen"
        (click)="setTitle('Self-Service Portal | MOVEit')"
        ><i class="pi pi-user-plus"></i> neuen benutzer
        anlegen</a
      >
      -->

      <a
        class="Unterpunkt hover:bg-blue-800 active:bg-blue-800"
        routerLink="/moveit/ordner"
        (click)="setTitle('Self-Service Portal | MOVEit')"
        ><i class="pi pi-folder-open"></i> ordnerverwaltung</a
      >

      <a
        class="Unterpunkt hover:bg-blue-800 active:bg-blue-800"
        routerLink="/moveit/reports"
        (click)="setTitle('Self-Service Portal | MOVEit')"
        ><i class="pi pi-table"></i> reports</a
      >
    </div>
    <div class="Kategorie" *ngIf="userInfo.admin || userInfo.keyUser">
      <a
        class="Punkt hover:bg-blue-800"
        routerLink="/admin"
        (click)="setTitle('Self-Service Portal | Administration')"
        >Administration</a
      >
    </div>
    <div
      class="grid grid-cols-2 Kategorie bottom-0 fixed w-1/6 text-base text-center"
    >
      <!--
      <a
        class="Punkt pl-0 normal-case hover:bg-blue-800"
        routerLink="/about"
        (click)="setTitle('Self-Service Portal | About')"
        >Über</a
      >
      -->
      <!--
      <a
        class="Punkt pl-0 normal-case hover:bg-blue-800"
        routerLink="/kontakt"
        (click)="setTitle('Self-Service Portal | Kontakt')"
        >Kontakt</a
      >
      -->
      <a
        class="Punkt pl-0 normal-case hover:bg-blue-800"
        href="https://bmshub.atlassian.net/wiki/spaces/SSPH/overview"
        target="_blank"
        >Hilfe</a
      >
      <a
        class="Punkt pl-0 normal-case hover:bg-blue-800"
        href="https://www.bertelsmann-marketing-services.com/impressum/"
        target="_blank"
        >Impressum</a
      >
    </div>
  </div>
  <div id="myNav" class="respnav bg-blue-500 mr-2 text-white">
    <!-- respnav content -->
    <div class="respnav-content">
      <div class="Kategorie">
        <a
          class="Punkt hover:bg-blue-800 whitespace-no-wrap block"
          routerLink="/"
          (click)="setTitle('Self-Service Portal'); closeNav()"
          ><i class="pi pi-home"></i> Home</a
        >
      </div>
      <div class="Kategorie">
        <a
          class="Punkt hover:bg-blue-800 whitespace-no-wrap block"
          routerLink="/anfragen"
          (click)="
            setTitle('Self-Service Portal | Meine Anfragen'); closeNav()
          "
        >
          <i class="pi pi-ticket"></i> Meine Anfragen</a
        >
      </div>
      <div class="Kategorie">
        <a
          class="Punkt hover:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
          >MOVEit</a
        >
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit/benutzer"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
        >
          <i class="pi pi-user"></i> Benutzerverwaltung</a
        >
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit/gruppen"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
          ><i class="pi pi-users"></i> Gruppenverwaltung</a
        >
        <!--
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit/anlegen"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
          ><i class="pi pi-user-plus"></i> Neuen Benutzer
          anlegen</a
        >
        -->
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit/ordner"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
          ><i class="pi pi-folder-open"></i> Ordnerverwaltung</a
        >
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap block"
          routerLink="/moveit/reports"
          (click)="setTitle('Self-Service Portal | MOVEit'); closeNav()"
          ><i class="pi pi-table"></i> Reports
        </a>
      </div>

      <div class="Kategorie">
        <!--
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap"
          routerLink="/about"
          (click)="setTitle('Self-Service Portal | About'); closeNav()"
          >Über</a
        >
        -->
        <!--
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap"
          routerLink="/kontakt"
          (click)="setTitle('Self-Service Portal | Kontakt'); closeNav()"
          >Kontakt</a
        >
        -->
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap"
          href="https://bmshub.atlassian.net/wiki/spaces/SSPH/overview"
          target="_blank"
          (click)="closeNav()"
          >Hilfe</a
        >
        <a
          class="Unterpunkt hover:bg-blue-800 active:bg-blue-800 whitespace-no-wrap"
          target="_blank"
          href="https://www.bertelsmann-marketing-services.com/impressum/"
          (click)="closeNav()"
          >Impressum</a
        >
      </div>

      <div class="Kategorie">
        <a
          class="Punkt hover:bg-blue-800 whitespace-no-wrap block"
          routerLink="/admin"
          (click)="
            setTitle('Self-Service Portal | Administration'); closeNav()
          "
        >
          Administration</a
        >
      </div>
    </div>
  </div>
  <!--Maincontent-->
  <div class="xl:ml-einsechstel">
    <div
      class="mt-20 ml-0 flex-initial bg-white overflow-hidden w-auto"
      id="main"
    >
      <div class="spinner-wrapper flex-col" *ngIf="loading">
        <div class="">
          <i class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
        </div>
        <div>Bitte warten ...</div>
      </div>
      <router-outlet> </router-outlet>
    </div>
  </div>
</div>
