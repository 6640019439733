import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {TreeNode} from 'primeng/api';
import {Observable} from 'rxjs';
import {map, retry, timeout} from 'rxjs/operators';
import {environment} from '@selfservice-portal-frontend/environments/environment';
import {FolderAccess} from '../../ordnerverwaltung/service/ordner.service';

interface TreeNodeResponse {
  data: TreeNode[];
}

export interface MoveItGroup {
  label: string;
  value: {
    id: string;
    name: string;
    description?: string;
  };
}

export interface MoveItUser {
  label: string;
  value: {
    id: string;
    username: string;
    realname: string;
  };
}

export interface ListFolderAccess {
  user: string;
  group: string;
  folder: string;
}

@Injectable({providedIn: 'root'})
export class MoveItService {
  constructor(private http: HttpClient) {
  }

  getFolderList(): Observable<TreeNode[]> {
    return this.http
      .get<TreeNodeResponse>(
        environment.moveitApiUrlPrefix + '/moveit-api/listFolders'
      )
      .pipe(
        retry(3),
        timeout(60000),
        map((response) => response.data)
      );
  }

  getUserList(): Observable<MoveItUser[]> {
    return this.http
      .get<MoveItUser[]>(
        environment.moveitApiUrlPrefix + '/moveit-api/listUsers'
      )
      .pipe(retry(3), timeout(60000));
  }

  getGroupList(): Observable<MoveItGroup[]> {
    return this.http
      .get<MoveItGroup[]>(
        environment.moveitApiUrlPrefix + '/moveit-api/listGroups'
      )
      .pipe(retry(3), timeout(60000));
  }

  getFolderAccess(
    listFolderAccess: ListFolderAccess
  ): Observable<FolderAccess> {
    if (listFolderAccess.user != null) {
      return this.http
        .post<FolderAccess>(
          environment.moveitApiUrlPrefix + '/moveit-api/listFolderAccess/user',
          listFolderAccess
        )
        .pipe(retry(3), timeout(60000));
    } else if (listFolderAccess.group != null) {
      return this.http
        .post<FolderAccess>(
          environment.moveitApiUrlPrefix + '/moveit-api/listFolderAccess/group',
          listFolderAccess
        )
        .pipe(retry(3), timeout(60000));
    } else {
      return this.http
        .post<FolderAccess>(
          environment.moveitApiUrlPrefix +
          '/moveit-api/listFolderAccess/folder',
          listFolderAccess
        )
        .pipe(retry(3), timeout(60000));
    }
  }

  getFolderAccessInherit(
    listFolderAccess: ListFolderAccess
  ): Observable<FolderAccess[]> {
    return this.http
      .post<FolderAccess[]>(
        environment.moveitApiUrlPrefix +
        '/moveit-api/listFolderAccess/folder/inherit',
        listFolderAccess
      )
      .pipe(retry(3), timeout(60000));
  }

  getUsersInGroup(groupName: string): Observable<MoveItUser[]> {
    const usersByGroup = {
      groupName: groupName,
    };

    return this.http
      .post<MoveItUser[]>(
        environment.moveitApiUrlPrefix + '/moveit-api/listUsers/group',
        usersByGroup
      )
      .pipe(retry(3), timeout(60000));
  }
}
