import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription, Subject, Observable, timer, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import {
  MeineAnfragenService,
  MeineAnfrage,
} from './service/meine-anfragen.service';
import { trigger, state, style } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { environment } from '@selfservice-portal-frontend/environments/environment';

@Component({
  selector: 'app-meine-anfragen',
  templateUrl: './meine-anfragen.component.html',
  styleUrls: ['./meine-anfragen.component.scss'],
  providers: [MeineAnfragenService, DatePipe],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-5%)',
          opacity: 0,
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
    ]),
  ],
})
export class MeineAnfragenComponent implements OnInit, OnDestroy {
  private dateFormat: string = 'yyyy-MM-dd HH:mm:ss';

  private meineAnfragenSubscription: Subscription;
  private routeChangeSubscription: Subscription;

  items: MenuItem[];
  home: MenuItem;
  meineAnfragen: MeineAnfrage[];
  cols: any[];
  time = 20; // Zeit die übrig ist (Time left)
  timePassed = 0; // Zeit die vergangen ist
  timeLimit = 20; // Zeit des Timers (wird nur für die Animation verwendet)
  interval;

  typeDescriptionMap: Record<string, string> = {
    AccessRightsRequestedEvent: 'Berechtigungsvergabe',
    GroupModificationRequestedEvent: 'Gruppenänderung',
    GroupRequestedEvent: 'Gruppenanlage',
    UserRequestedEvent: 'Benutzeranlage',
    FolderRequestedEvent: 'Ordneranlage',
    FolderOwnershipChangeRequestedEvent: 'Ordnerbesitzeränderung',
    GroupOwnershipChangeRequestedEvent: 'Gruppenbesitzeränderung',
    UserUnlockRequestedEvent: 'Benutzer entsperren',
    FolderOwnershipMassChangeRequestedEvent: 'Massen-Ordnerbesitzeränderung',
  };

  statusDescriptionMap: Record<string, string> = {
    REQUESTED: 'Beantragt',
    CANNOT_REQUEST: 'Fehlgeschlagen',
    REQUEST_APPROVED: 'Genehmigt',
    MOVEIT_CONFIGURED: 'MOVEit angepasst',
    REQUEST_FULFILLED: 'Erledigt',
    REQUEST_DENIED: 'Abgelehnt',
  };

  // Kill subject to stop all requests for component
  private killTrigger: Subject<void> = new Subject();

  private fetchData$: Observable<
    MeineAnfrage[]
  > = this.meineAnfragenService.getMeineAnfragen();

  private refreshInterval$: Observable<MeineAnfrage[]> = timer(
    0,
    this.timeLimit * 1000
  ).pipe(
    // This kills the request if the user closes the component
    takeUntil(this.killTrigger),
    // switchMap cancels the last request, if no response have been received since last tick
    switchMap(() => this.fetchData$)
  );

  public meineAnfragen$: Observable<MeineAnfrage[]> = this.refreshInterval$;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
      }

      this.setCircleDasharray();

      //console.log(this.time);
    }, 1000);
  }

  private calculateTimeFraction() {
    const rawTimeFraction = this.time / this.timeLimit;
    return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
  }

  // Update the dasharray value as time passes, starting with 283
  private setCircleDasharray() {
    if (this.time == 0) {
      document
        .getElementById('base-timer-path-remaining')
        .setAttribute('stroke-dasharray', '283');
    } else {
      const circleDasharray = `${(this.calculateTimeFraction() * 283).toFixed(
        0
      )} 283`;
      document
        .getElementById('base-timer-path-remaining')
        .setAttribute('stroke-dasharray', circleDasharray);
    }
  }

  resetTimer() {
    this.time = 20;
    this.timePassed = 0;
  }

  clearInterval() {
    clearInterval(this.interval);
  }

  constructor(
    private meineAnfragenService: MeineAnfragenService,
    private route: ActivatedRoute,
    public datepipe: DatePipe
  ) {}

  loadData(): void {
    this.meineAnfragenSubscription = this.meineAnfragen$.subscribe({
      next: (meineAnfragen) => {
        this.meineAnfragen = meineAnfragen;
        this.meineAnfragen.forEach((anfrage) => {
          this.formatDateToCurrentTimezone(anfrage);
        });
        this.resetTimer();
      },
      complete: () => {
        this.meineAnfragenSubscription.unsubscribe();
      },
    });
  }

  formatDateToCurrentTimezone(anfrage: MeineAnfrage) {
    // convert 2020-05-05T06:06:12.797Z to user timezone
    if (anfrage.initialRequestDate != null) {
      anfrage.initialRequestDate = this.datepipe.transform(
        new Date(anfrage.initialRequestDate),
        this.dateFormat
      );
    }
    if (anfrage.lastUpdate != null) {
      anfrage.lastUpdate = this.datepipe.transform(
        new Date(anfrage.lastUpdate),
        this.dateFormat
      );
    }
  }

  typeDescription(key: string): string {
    // BPGSSP-56
    return this.typeDescriptionMap[key];
  }

  statusDescription(key: string): string {
    // BPGSSP-56
    return this.statusDescriptionMap[key];
  }

  generateJiraUrl(ticketId: string): string {
    return environment.jiraBaseUrl + ticketId;
  }

  ngOnDestroy(): void {
    this.meineAnfragenSubscription.unsubscribe();
    this.routeChangeSubscription.unsubscribe();
    this.killTrigger.next();
    this.clearInterval();
  }

  ngOnInit() {
    this.time = 20;
    this.timePassed = 0;
    this.timeLimit = 20;
    this.routeChangeSubscription = this.route.params.subscribe({
      next: (_: Params) => this.loadData(),
    });

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Meine Anfragen', routerLink: '/anfragen' }];

    this.cols = [
      { field: 'jiraTicketId', header: 'JIRA-Ticket-ID' },
      { field: 'requestType', header: 'Typ' },
      { field: 'initialRequestDate', header: 'Beantragungsdatum' },
      { field: 'requestStatus', header: 'Status' },
    ];

    this.startTimer();
  }
}
